import { Client } from "xrpl";

/**
 * Get the latest block number from the XRPL
 * @returns the latest block number
 */
//TODO get the network context to get the correct network
export async function getLatestXrplBlockNumber(
  network: string,
): Promise<number> {
  let xrplWssUrl;
  switch (network) {
    case "mainnet":
      xrplWssUrl = "wss://s1.ripple.com";
      break;
    case "testnet":
      xrplWssUrl = process.env.REACT_APP_RIPPLE_TESTNET_WSS;
      if (!xrplWssUrl) xrplWssUrl = "wss://s.altnet.rippletest.net:51233/";
      break;
    default:
      throw new Error("Invalid network");
  }

  const client = new Client(xrplWssUrl);
  await client.connect();
  let data;

  try {
    data = (await client.request({
      id: 2,
      command: "ledger_current",
    })) as {
      id: 2;
      status: string;
      type: string;
      result: {
        ledger_current_index: number;
      };
    };
  } catch (e) {
    throw new Error("failed to get tx history: " + e);
  }
  await client.disconnect();

  if (!data) throw new Error("failed to get tx history: " + data);
  return data.result.ledger_current_index;
}

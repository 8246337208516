const API_URL = process.env.REACT_APP_API_URL;

/* -----------------BACKEND API URL----------------- */
export const API_RING_GENERATION_URL = `${API_URL}api/initRingGeneration/`;
export const API_MINT_URL = `${API_URL}api/mint/`;
export const API_VERIFY_URL = `${API_URL}api/verifyProof`;
export const API_GET_POAPS_URL = `${API_URL}api/getPoaps/`;
export const API_GET_POAP_HOLDERS_URL = `${API_URL}api/poapHolders/`;
export const API_GET_POAP_EVENT_DATA = `${API_URL}api/getPoapEventData/`;

/* -----------------IPFS READ URL----------------- */
export const IPFS_READ_BASE_URL = "https://api2.aleph.im/api/v0/storage/raw/";

/* -----------------EXPLORER URL----------------- */
export const XRPL_EXPLORER_BASE_URL = "https://test.bithomp.com/explorer/";
export const POAP_DROP_EXPLORER_BASE_URL = "https://collectors.poap.xyz/drop/";
export const POAP_EXPLORER_BASE_URL = "https://collectors.poap.xyz/scan/";

/* -----------------XRPL PROVIDER URL----------------- */
export const RIPPLE_TESTNET_HTTPS =
  process.env.REACT_APP_RIPPLE_TESTNET_HTTPS ??
  "https://s.altnet.rippletest.net:51234/	";
export const RIPPLE_TESTNET_WSS =
  process.env.REACT_APP_RIPPLE_TESTNET_WSS ??
  "wss://s.altnet.rippletest.net:51233/";
export const RIPPLE_MAINNET_HTTPS =
  process.env.REACT_APP_RIPPLE_MAINNET_HTTPS ?? "https://s1.ripple.com:51234/";
export const RIPPLE_MAINNET_WSS =
  process.env.REACT_APP_RIPPLE_MAINNET_WSS ?? "wss://s1.ripple.com/";

/* -----------------API REQUEST TYPES----------------- */
export enum JSON_RPC_REQUEST_METHOD {
  INIT_RING_GENERATION = "ring_generation_init",
  INIT_RING_GENERATION_RESPONSE = "ring_generation_init_response",
  MINT_REQUEST = "mint_request",
  MINT_REQUEST_RESPONSE = "mint_request_response",
  GET_POAPS = "get_poaps",
}

export enum SupportedCurrencies {
  XRP = "XRP",
  POAP = "POAP",
}

export enum SupportedCurve {
  secp256k1 = "secp256k1",
  ed25519 = "ed25519",
}

/* -----------------SIGNATURE CONFIG----------------- */
/*export const BASE_CONFIG: SignatureConfig = {
  hash: hashFunction.KECCAK256,
  evmCompatibility: false,
  linkable: {
    enabled: false,
    linkabilityFlag: undefined,
    keyImage: undefined,
  },
  debug: false,
}*/

// if true, the front will automatically verify the signature before submitting it to the backend
export const AUTO_VERIFY_RING_SIG = true;

/* -----------------CUSTOM MESSAGE----------------- */
export const CUSTOM_MESSAGE_MAX_MESSAGE_LENGTH = 100; // max length of the message (bytes)

/* -----------------RING SIZE----------------- */
export const MAX_RING_SIZE = 5000; // ringSize > MAX_RING_SIZE
export const MIN_RING_SIZE = 2; // ringSize < MIN_RING_SIZE

/* -----------------PAYMENT RECEIVING ADDRESS----------------- */
export const CYPHERLAB_XRPL_XRPLSOLVENCY_RECEIVING_ADDRESS =
  "rMAF4xyJd3tBpgSU5quKHU6kCxHX3shdS7";

/* -----------------PAYMENT RECEIVING ADDRESS----------------- */
// Used to generate the payment id for the free tier
export const FREE_TIER_PAYMENT_ID = "freeTier";

/* -----------------NFT METADATA----------------- */
export const NFT_STANDARD_METADATA = {
  schema: "ipfs://QmNpi8rcXEkohca8iXu7zysKKSJYqCvBJn3xJwga8jXqWU",
  nftType: "art.v0",
  name: "Alice's Ring Proof of Solvency",
  description: "A proof of solvency generated by Alice's Ring",
  image:
    "https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExNzNpMHVqM3kybGNlMzU5bmhrb2wyeWFyZGNzbTdtbHpodjlqMnBqbiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/ncv0kDPyKAUyP4wcRQ/giphy-downsized-large.gif",
  animation: "QmaEgw5HYbPi28YiHUvEhaWMG9rNgmtGiakBE4SuChEKWh",
  issuer: "Alice's Ring",
  network: "testnet",
};

/* -----------------PRICING FUNCTION----------------- */
export interface PriceParameters {
  currency: SupportedCurrencies;
  ringSize: number;
  customMessage?: string;
}

// returns the price to pay in the smallest unit of the currency
export const pricing = (params: PriceParameters): bigint => {
  /*switch (params.currency) {
    case SupportedCurrencies.XRP:
      return BigInt(
        Math.round(
          (params.ringSize / 1000) * 2 * 10 ** 6, // convert to drops
        ),
      );
    default:
      throw new Error("Unsupported currency");
      }*/
  return 0n;
};

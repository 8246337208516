import {
  SendPaymentRequest,
  isInstalled,
  sendPayment,
} from "gemwallet-custom-api";

function formatAmount(value: bigint, issuer: string, currency?: string) {
  if (currency) {
    return {
      currency,
      issuer,
      value: `${value}`,
    };
  }

  return {
    issuer,
    value: `${value}`,
  };
}

/**
 * Send a payment on xrpl using gem wallet
 *
 * @param amount - The amount to send (in the currency of the destination) (in the smallest unit)
 * @param destination - The destination address
 *
 * @returns The transaction id
 */
export async function payWithGem(
  amount: bigint,
  destination: string,
  currency?: string,
): Promise<string> {
  if ((await isInstalled()).result?.isInstalled) {
    if (amount <= 0) throw new Error("Amount must be positive"); // Network rejects 0 amount

    if (currency && currency === "XRP") currency = undefined;

    try {
      let payment;
      if (currency) {
        payment = {
          amount: formatAmount(amount, destination, currency),
          destination: destination,
          memos: [
            {
              memo: {
                // TODO: HOW SHOULD WE SETUP THIS ?
                memoType: "4465736372697074696f6e",
                memoData: "54657374206d656d6f",
              },
            },
          ],
          destinationTag: 12, // TODO: HOW SHOULD WE SETUP THIS ?
          fee: "199", // TODO: HOW TO CALCULATE THIS ?
          flags: {
            tfNoDirectRipple: false,
            tfPartialPayment: false,
            tfLimitQuality: false,
          },
        } as SendPaymentRequest;
      } else {
        payment = {
          amount: amount.toString(), // In drops
          destination: destination,
        };
        const paymentResult = await sendPayment(payment);

        if (paymentResult.result?.hash) {
          return paymentResult.result?.hash;
        }
      }

      throw new Error("Could not get send payment from gemWallet");
    } catch (e: any) {
      console.error("Something went wrong: ", e);
      throw new Error(
        "Something went wrong while sending payment from gemWallet: ",
        e,
      );
    }
  }

  throw new Error("Something went wrong while sending payment from gemWallet");
}

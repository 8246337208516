import { API_GET_POAPS_URL } from "../../constant";

export async function getPoaps(address: string): Promise<{ eventId: string, tokenId: string, poapEvent: { eventName: string, description: string }, tokenUri: string}[] | null> {
  try {
    const response = await fetch(`${API_GET_POAPS_URL}${address}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const poaps = await response.json();

    if (response.ok) {
      return poaps;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Failed to fetch Poaps:", error);
    return null;
  };
}
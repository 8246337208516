import { isInstalled, getPublicKey } from "gemwallet-custom-api";

/**
 * get the current address from gemWallet
 *
 * @returns the current address (string)
 */
export async function getAddressFromGem(): Promise<string> {
  if ((await isInstalled()).result?.isInstalled) {
    try {
      const address = (await getPublicKey()).result?.address;

      if (address) {
        return address;
      }
      throw new Error();
    } catch (e: any) {
      console.error("Something went wrong: ", e);
      throw new Error(
        "Something went wrong while getting the current gem address: ",
        e,
      );
    }
  }

  throw new Error("Something went wrong while getting the current gem address");
}

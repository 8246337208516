import { useEffect } from "react";
import { Box, Button, Typography, Link as MuiLink } from "@mui/material";
import {
  getPublicKey,
  isInstalled,
  on,
  getNetwork,
} from "gemwallet-custom-api";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useWalletInfo } from "../context/walletInfo";
import { useNetworkInfo } from "../context/networkInfo";
import { useGwInfo } from "../context/gwInfo";
import "./WalletConnector.css";

interface WalletConnectorProps {
  onInstallationCheck: (isInstalled: boolean) => void;
  onWalletConnected?: (isConnected: boolean) => void;
}

const UserWalletInfo: React.FC<{ address: string; network: string }> = ({
  address,
  network,
}) => {
  const truncateAddress = (addr: string) =>
    `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;

  return (
    <Box className="user-info-container">
      <Box className="user-info-box">
        <Typography className="address-text" variant="body2" title={network}>
          {network}
        </Typography>
      </Box>
      <Box className="user-info-box">
        <AccountBalanceWalletIcon className="wallet-icon" />
        <Typography className="address-text" variant="body2" title={address}>
          {truncateAddress(address)}
        </Typography>
      </Box>
    </Box>
  );
};

const WalletConnector: React.FC<WalletConnectorProps> = ({
  onInstallationCheck,
  onWalletConnected,
}) => {
  const { userWalletInfo, setUserWalletInfo } = useWalletInfo();
  const { userNetworkInfo, setUserNetworkInfo } = useNetworkInfo();
  const { gwInfo, setGwInfo } = useGwInfo();

  const checkWalletInstallation = async () => {
    const installResponse = await isInstalled();
    onInstallationCheck(installResponse.result.isInstalled);
    if (installResponse.result.isInstalled) {
      const pubKeyResponse = await getPublicKey();
      const networkResponse = await getNetwork();
      setUserWalletInfo({ address: pubKeyResponse.result?.address ?? "" });
      setUserNetworkInfo(networkResponse.result?.network.toLowerCase() ?? "");
      if (pubKeyResponse.result?.address) {
        onWalletConnected?.(true);
      }
    }
  };

  useEffect(() => {
    const walletChangedHandler = (response: any) => {
      if (response.wallet && response.wallet.publicAddress) {
        setUserWalletInfo({ address: response.wallet.publicAddress });
        onInstallationCheck(true);
        onWalletConnected?.(true);
      }
    };

    const networkChangedHandler = (response: any) => {
      if (response.network && response.network.name) {
        setUserNetworkInfo(response.network.name.toLowerCase());
        onInstallationCheck(true);
        onWalletConnected?.(true);
      }
    };

    on("walletChanged", walletChangedHandler);
    on("networkChanged", networkChangedHandler);
  }, [
    setUserWalletInfo,
    setUserNetworkInfo,
    onInstallationCheck,
    onWalletConnected,
  ]);

  const checkConnection = async () => {
    await checkWalletInstallation();
    setGwInfo(true);
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        padding: "20px",
        color: "white",
      }}
    >
      {gwInfo ? (
        userWalletInfo ? (
          <UserWalletInfo
            address={userWalletInfo.address}
            network={userNetworkInfo}
          />
        ) : (
          <Typography variant="body1">
            GemWallet is not installed.
            <br />
            Please visit{" "}
            <MuiLink
              href="https://gemwallet.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>GemWallet</b>
            </MuiLink>{" "}
            to install the browser wallet extension.
          </Typography>
        )
      ) : (
        <Button
          variant="contained"
          onClick={checkConnection}
          sx={{
            background: "linear-gradient(to left, #C919BC 52%, #6E078E)",
            textTransform: "none",
            "&:hover": {
              background: "linear-gradient(to left, #e740db 52%, #C919BC)",
            },
          }}
        >
          Connect with GemWallet💎
        </Button>
      )}
    </Box>
  );
};

export default WalletConnector;

import { API_GET_POAP_HOLDERS_URL } from "../../constant";

// get a list of poap holders from an eventId
export async function getPoapHolders(eventId: string): Promise<string[] | null> {
  const result = await fetch(`${API_GET_POAP_HOLDERS_URL}${eventId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const holders = await result.json();
  if (result.ok) {
    return holders;
  } else {
    return null;
  }
}
import { burnNFT, isInstalled } from "gemwallet-custom-api";

/**
 * Burn an NFT on the XRPL using GemWallet
 *
 * @param token_id - The tokenID of the NFT to burn
 *
 * @returns The transaction hash
 */
export async function burnNFTGem(token_id: string): Promise<string> {
  if ((await isInstalled()).result?.isInstalled) {
    if (!token_id) throw new Error("Token id is required");
    try {
      const burnNFTTx = await burnNFT({
        NFTokenID: token_id,
      });
      if (burnNFTTx.result?.hash) {
        return burnNFTTx.result?.hash;
      }
      throw new Error(
        "Something went wrong while sending payment from gemWallet",
      );
    } catch (e: any) {
      console.error("Something went wrong: ", e);
      throw new Error(
        "Something went wrong while sending payment from gemWallet: ",
      );
    }
  }
  throw new Error("Something went wrong while sending payment from gemWallet");
}

import {
  Box,
  Button,
  Typography,
  alpha,
  CircularProgress,
} from "@mui/material";
import { isInstalled } from "gemwallet-custom-api";
import { useEffect, useState } from "react";
import WalletConnector from "../../WalletConnector";

const handleDownloadExtension = () => {
  const extensionZipUrl =
    "https://github.com/Cypher-Laboratory/gem_Wallet_custom_dist/archive/refs/heads/main.zip";

  const downloadLink = document.createElement("a");
  downloadLink.href = extensionZipUrl;
  downloadLink.setAttribute("download", "extension.zip");
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

const DownloadWallet = () => {
  const [gwIsInstalled, setGwIsInstalled] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleCheckWallet = (isInstalled: boolean) => {
    setGwIsInstalled(isInstalled);
  };

  useEffect(() => {
    const checkIfWalletIsInstalled = async () => {
      const installResponse = await isInstalled();
      setGwIsInstalled(installResponse.result.isInstalled);
      setLoading(false);
    };

    setTimeout(checkIfWalletIsInstalled, 200);
  }, []);

  return (
    <Box
      sx={{
        p: 2,
        border: "1px solid purple",
        borderRadius: "15px",
        textAlign: "center",
        color: "#FAF9F6",
        marginBottom: "10px",
        backgroundColor: alpha("#1e1e2f", 0.6),
        mx: "auto",
        maxWidth: "90vw",
        width: "100%",
      }}
    >
      {loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          {gwIsInstalled ? (
            <>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "0.9rem", marginBottom: "10px", mt: 0 }}
              >
                Please connect with GemWallet first
              </Typography>
              <WalletConnector onInstallationCheck={handleCheckWallet} />
            </>
          ) : (
            <>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "0.9rem", marginBottom: "20px", mt: 0 }}
              >
                Don't have GemWallet💎 yet?
              </Typography>

              <Button
                onClick={handleDownloadExtension}
                variant="contained"
                sx={{
                  background: "linear-gradient(to left, #C919BC 52%, #6E078E)",
                  color: "white",
                  textTransform: "none",
                  "&:hover": {
                    background:
                      "linear-gradient(to left, #e740db 52%, #C919BC)",
                  },
                  ml: 2,
                }}
              >
                Download Extension
              </Button>
            </>
          )}
        </>
      )}
    </Box>
  );
};
export default DownloadWallet;

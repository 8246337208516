export const tableCellStyleBold = {
  fontWeight: "bold",
  borderBottom: "none", // Set text color to #FAF9F6
  padding: "16px", // Consistent padding
  fontSize: "0.875rem", // Adjust font size as needed
  color: "#FAF9F6", // Content color for readability
};

export const tableCellStyleNormal = {
  fontWeight: "normal",
  borderBottom: "none", // Set text color to #FAF9F6
  padding: "16px", // Consistent padding
  fontSize: "0.875rem", // Adjust font size as needed
  color: "#FAF9F6", // Content color for readability
};

export const tableRowStyle = {
  backgroundColor: "#1A1A1A", // Row background color
  "&:hover": {
    backgroundColor: "#292929", // Row hover background color
  },
  transition: "background-color 0.3s", // Smooth transition for hover effect
};

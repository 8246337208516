import React from "react";

const WalletInfoContext = React.createContext();
export const useWalletInfo = () => React.useContext(WalletInfoContext);
export const WalletInfoProvider = ({ children }) => {
  const [userWalletInfo, setUserWalletInfo] = React.useState("");

  return (
    <WalletInfoContext.Provider value={{ userWalletInfo, setUserWalletInfo }}>
      {children}
    </WalletInfoContext.Provider>
  );
};

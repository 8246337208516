import { getPublicKey, isInstalled } from "gemwallet-custom-api";

/**
 * get public key from gemWallet
 *
 * @returns public key (string)
 */
export async function getPublicKeysFromGem(): Promise<{
  gemPublicKey: string;
}> {
  if ((await isInstalled()).result?.isInstalled) {
    try {
      const pubkeys = (await getPublicKey()).result;

      if (pubkeys) {
        return { gemPublicKey: pubkeys.publicKey };
      }

      throw new Error("Could not get public keys from gemWallet");
    } catch (e: any) {
      console.error("Something went wrong: ", e);
      throw new Error(
        "Something went wrong while getting public key from gemWallet: ",
        e,
      );
    }
  }

  throw new Error(
    "Something went wrong while getting public key from gemWallet",
  );
}

import React from "react";

const GwInfoContext = React.createContext();
export const useGwInfo = () => React.useContext(GwInfoContext);
export const GwInfoProvider = ({ children }) => {
  const [gwInfo, setGwInfo] = React.useState(false);

  return (
    <GwInfoContext.Provider value={{ gwInfo, setGwInfo }}>
      {children}
    </GwInfoContext.Provider>
  );
};

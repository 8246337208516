import React, { useEffect, useState } from "react";
import { useWalletInfo } from "../../../context/walletInfo";
import {
  IpfsStoredData,
  MintRequestResult,
  P2,
  P3,
  SigningResult,
} from "../../../interfaces";
import { Snackbar, Alert } from "@mui/material";
import { acceptNFT } from "../../../utils/gem/acceptNFTOffer";
import MintRequestDetails from "./MintRequestDetails";
import MintRequestModal from "./MintRequestModel";
import { pointToXRPLAddress, signWithGem } from "../../../utils";
import {
  API_MINT_URL,
  IPFS_READ_BASE_URL,
  JSON_RPC_REQUEST_METHOD,
} from "../../../constant";
import { RingSignature } from "@cypher-laboratory/alicesring-sag";

const MintRequest: React.FC<{
  data: SigningResult | null;
  onMintRequestResult: (result: MintRequestResult) => void;
  onCloseClick: () => void; // Add onCloseClick prop
}> = ({ data, onMintRequestResult, onCloseClick }) => {
  const [isAddressCorrect, setIsAddressCorrect] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackBarStatus, setSnackBarStatus] = useState<"success" | "error">(
    "success",
  );
  const [expanded, setExpanded] = useState<string | false>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nftData, setNftData] = useState<{ mintTx: string; uri: string }>({
    mintTx: "",
    uri: "",
  });
  const [isClaimSuccessful, setIsClaimSuccessful] = useState(false);
  const [nftOfferId, setNftOfferId] = useState<string>("");
  const [nftDataFromIPFS, setNftDataFromIPFS] = useState<IpfsStoredData>();
  const [openModal, setOpenModal] = useState(true); // State to control the visibility of the modal
  const [ring, setRing] = useState<string[]>();

  const { userWalletInfo } = useWalletInfo();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    try {
      if (userWalletInfo.address === data?.provingAddress) {
        setIsAddressCorrect(true);
        setSnackbarMessage("Address is correct.");
        setSnackbarOpen(true);
      } else {
        setIsAddressCorrect(false);
        setSnackbarMessage(
          "The current address in GemWallet does not match the required Secret Address.",
        );
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error fetching wallet address: ", error);
      setSnackbarMessage("Error checking address: " + error);
      setSnackbarOpen(true);
    }
  }, [userWalletInfo]);

  useEffect(() => {
    isAddressCorrect
      ? setSnackBarStatus("success")
      : setSnackBarStatus("error");
  }, [isAddressCorrect]);

  if (!data) return <p>Please complete the previous step first</p>;

  const { paymentId, ringSignature, provingAddress } = data;

  const handleMintClick = async () => {
    console.log("Start mint process...");
    setIsLoading(true);

    try {
      const mintBody: P2 = {
        type: JSON_RPC_REQUEST_METHOD.MINT_REQUEST,
        data: {
          signatureId: paymentId,
          ringSignature,
          signature: await signWithGem(
            JSON.stringify({ signatureId: paymentId, ringSignature }),
            provingAddress,
          ),
        },
      };

      const mintApiResponse = await fetch(API_MINT_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(mintBody),
      });

      if (mintApiResponse.ok) {
        const result: P3 = await mintApiResponse.json();
        if (
          !(
            result.type === JSON_RPC_REQUEST_METHOD.MINT_REQUEST_RESPONSE &&
            result.data.status &&
            result.data.nftMintTx &&
            result.data.proofURI
          )
        ) {
          throw new Error(
            "Error minting proof: missing data in response: received: " +
            result.data,
          );
        }

        setSnackbarMessage(
          'Proof minted successfully. Please accept the NFT offer by clicking the "Claim" button.',
        );
        setSnackbarOpen(true);
        setNftData({
          mintTx: result.data.nftMintTx,
          uri: result.data.proofURI,
        });

        const data: Response = await fetch(
          IPFS_READ_BASE_URL + result.data.proofURI,
        );
        const jsonData = await data.json();
        setNftDataFromIPFS(jsonData);

        const ringSig = RingSignature.fromBase64(
          jsonData?.additional?.signature ?? "",
        );
        if (JSON.parse(ringSig.getMessage()).currency && JSON.parse(ringSig.getMessage()).currency === "POAP") {
          setRing(ringSig.getRing().map((p) => p.toEthAddress()));
        } else {
          setRing(ringSig.getRing().map((p) => pointToXRPLAddress(p)));
        }

        setOpenModal(false);
        console.log("Mint successful: ", result.data);
        try {
          await acceptNFT(result.data.sellOfferId as string);
          setIsClaimSuccessful(true);
          setSnackbarMessage("NFT claimed successfully.");
        } catch (acceptError) {
          console.error("Accept NFT error: ", acceptError);
          setNftOfferId(result.data.sellOfferId as string);
          setIsClaimSuccessful(false);
          setSnackbarMessage(`Claim error: ${acceptError}`);
        }
      } else {
        const error = await mintApiResponse.text();
        setSnackBarStatus("error");
        setSnackbarMessage("Mint error: " + error);
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Mint error ", error);
      setSnackBarStatus("error");
      setSnackbarMessage("Mint error: " + error);
      setSnackbarOpen(true);
    }
    setIsLoading(false);
  };

  const handleClaimClick = async () => {
    try {
      await acceptNFT(nftOfferId);
      setIsClaimSuccessful(true);
      setSnackbarMessage("NFT claimed successfully.");
    } catch (error) {
      console.error("Claim error ", error);
      setSnackbarMessage("Claim error: " + error);
    }

    console.log("Claim successful");
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <MintRequestModal
        openModal={openModal}
        provingAddress={provingAddress}
        handleMintClick={handleMintClick}
        isAddressCorrect={isAddressCorrect}
        isLoading={isLoading}
      />
      {nftData.mintTx && nftData.uri && ring && (
        <MintRequestDetails
          isClaimSuccessful={isClaimSuccessful}
          nftData={nftData}
          nftDataFromIPFS={nftDataFromIPFS}
          ring={ring}
          expanded={expanded}
          handleChange={handleChange}
          handleClaimClick={handleClaimClick}
          handleCloseClick={onCloseClick} // Pass onCloseClick prop
        />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackBarStatus}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MintRequest;

import { Curve, CurveName, Point } from "@cypher-laboratory/alicesring-sag";
import * as elliptic from "elliptic";
/**
 * Converts a hex public key (XRPL standard) to a Point object.
 *
 * @param hex the hex string representation of the public key XRPL Format
 * @returns the point
 *
 */
export function pointFromHexXRPL(hex: string): Point {
  const Ed25519 = new elliptic.eddsa("ed25519");
  const secp256k1 = new elliptic.ec("secp256k1");
  // Check which curve we are on
  if (hex.startsWith("ED")) {
    // Compute on ed25519
    try {
      // Use the `curve.keyFromPublic` method to create a Keypair based on the signing pubkey
      // The keypair is encoded
      // Get ride of the ED prefix indicating that the curve is on ed25519
      const keypair = Ed25519.keyFromPublic(hex.slice(2));
      // get the X and Y value by decoding the point
      const xValue = Ed25519.decodePoint(keypair.getPublic())
        .getX()
        .toString("hex");
      const yValue = Ed25519.decodePoint(keypair.getPublic())
        .getY()
        .toString("hex");
      return new Point(new Curve(CurveName.ED25519), [
        BigInt("0x" + xValue),
        BigInt("0x" + yValue),
      ]);
    } catch (error) {
      throw new Error("error while computing coordinates on ed25519");
    }
  } else {
    // Compute on secp256k1
    try {
      // Use the `curve.pointFromX()` method to retrieve the point on the curve
      // Get ride of the prefix (02/03) that indicate if y coordinate is odd or not
      // see xrpl doc here : https://xrpl.org/cryptographic-keys.html
      const point = secp256k1.curve.pointFromX(hex.slice(2));
      // Access the y-coordinate from the retrieved point
      const xValue = point.getX().toString("hex");
      const yValue = point.getY().toString("hex");
      return new Point(new Curve(CurveName.SECP256K1), [
        BigInt("0x" + xValue),
        BigInt("0x" + yValue),
      ]);
    } catch (error) {
      throw new Error("error while computing coordinates on secp256k1");
    }
  }
}

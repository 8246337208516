import {
  RIPPLE_MAINNET_HTTPS,
  RIPPLE_TESTNET_HTTPS,
  RIPPLE_MAINNET_WSS,
  RIPPLE_TESTNET_WSS,
} from "../constant";

export function getHTTPSNetworkProvider(network: string): string {
  switch (network) {
    case "mainnet":
      return RIPPLE_MAINNET_HTTPS;
    case "testnet":
      return RIPPLE_TESTNET_HTTPS;
    default:
      throw new Error("Unsupported network");
  }
}

export function getWSSNetworkProvider(network: string): string {
  switch (network) {
    case "mainnet":
      return RIPPLE_MAINNET_WSS;
    case "testnet":
      return RIPPLE_TESTNET_WSS;
    default:
      throw new Error("Unsupported network");
  }
}

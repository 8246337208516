import React, { useMemo, useState, useEffect } from "react";
import { Box, Card, CardMedia } from "@mui/material";
import "./YoutubePlayer.css";

interface YouTubeVideoProps {
  videoId: string;
  title?: string;
}

const YoutubePlayer: React.FC<YouTubeVideoProps> = ({ videoId, title }) => {
  const videoSrc = useMemo(
    () => `https://www.youtube.com/embed/${videoId}`,
    [videoId],
  );

  const [dimensions, setDimensions] = useState(() => {
    const maxWidth = window.innerWidth * 0.5;
    const aspectRatio = 9 / 16;
    return { width: maxWidth, height: maxWidth * aspectRatio };
  });

  useEffect(() => {
    const handleResize = () => {
      const maxWidth = window.innerWidth * 0.5;
      const aspectRatio = 9 / 16;
      setDimensions({ width: maxWidth, height: maxWidth * aspectRatio });
    };

    const debouncedHandleResize = debounce(handleResize, 100);

    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  return (
    <Box className="youtube-player-container">
      <Card className="youtube-player-card">
        <CardMedia
          component="iframe"
          className="youtube-player-media"
          style={{ height: dimensions.height }}
          src={videoSrc}
          title={title || "YouTube Video"}
        />
      </Card>
    </Box>
  );
};

export default YoutubePlayer;

function debounce(func: (...args: any[]) => void, wait: number) {
  let timeout: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

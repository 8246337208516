import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import GenerateProofComponent from "./GenerateProofComponent";
import VerifyProofComponent from "./panel/verify/VerifyProofComponent";
import Overview from "./panel/myProofs/Overview";
import FeedbackIcon from "@mui/icons-material/Feedback";
import YoutubePlayer from "./YoutubePlayer";
import MobileDetect from "mobile-detect";

const isMobileDevice = () => {
  const md = new MobileDetect(window.navigator.userAgent);
  return md.mobile() !== null;
};

const MainContent: React.FC = () => {
  const [activeMenu, setActiveMenu] = useState(
    isMobileDevice() ? "verify" : "generate",
  );
  const [isMobile, setIsMobile] = useState(isMobileDevice());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileDevice());
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const switchMenu = (
    menu: "generate" | "verify" | "myProofs" | "tutorial",
  ) => {
    setActiveMenu(menu);
  };

  const handleFeedbackClick = () => {
    const typeformLink = process.env.REACT_APP_FEEDBACK_FORM_LINK;
    if (typeformLink) {
      window.open(typeformLink, "_blank");
    }
  };

  const menuButtonStyle = useCallback(
    (menuName: string) => ({
      mx: 1,
      color: activeMenu === menuName ? "white" : "grey",
      borderBottom: "none",
      "&:hover": {
        color: "white",
      },
      textTransform: "none",
      fontSize: "1rem",
      fontWeight: "bold",
    }),
    [activeMenu],
  );

  const getActiveMenuContent = () => {
    return (
      <>
        {isMobile ? (
          <>
            <Box
              sx={{
                display: activeMenu === "verify" ? "block" : "none",
                p: 0,
                m: 0,
                width: "100%",
              }}
            >
              <VerifyProofComponent />
            </Box>
            <Box
              sx={{
                display: activeMenu === "generate" ? "block" : "none",
                p: 0,
                m: 0,
                width: "100%",
              }}
            >
              Unavailable on mobile, please switch to desktop
            </Box>
            <Box
              sx={{
                display: activeMenu === "myProofs" ? "block" : "none",
                p: 0,
                m: 0,
                width: "100%",
              }}
            >
              Unavailable on mobile, please switch to desktop
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: activeMenu === "generate" ? "block" : "none",
                p: 0,
                m: 0,
                width: "100%",
              }}
            >
              <GenerateProofComponent />
            </Box>
            <Box
              sx={{
                display: activeMenu === "verify" ? "block" : "none",
                p: 0,
                m: 0,
                width: "100%",
              }}
            >
              <VerifyProofComponent />
            </Box>
            <Box
              sx={{
                display: activeMenu === "myProofs" ? "block" : "none",
                p: 0,
                m: 0,
                width: "100%",
              }}
            >
              <Overview />
            </Box>
            <Box
              sx={{
                display: activeMenu === "tutorial" ? "block" : "none",
                p: 0,
                m: 0,
                width: "100%",
              }}
            >
              <YoutubePlayer
                videoId="BxK-vaeP7CU"
                title="XRPL-Solvency Tutorial"
              />
            </Box>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          borderBottom: "none",
          pb: 1,
        }}
      >
        <Button
          onClick={() => switchMenu("verify")}
          sx={menuButtonStyle("verify")}
        >
          Verify
        </Button>
        <Button
          onClick={() => switchMenu("generate")}
          sx={menuButtonStyle("generate")}
        >
          Generate
        </Button>
        <Button
          onClick={() => switchMenu("myProofs")}
          sx={menuButtonStyle("myProofs")}
        >
          My proofs
        </Button>
        {!isMobile && (
          <Button
            onClick={() => switchMenu("tutorial")}
            sx={menuButtonStyle("tutorial")}
          >
            Tutorial
          </Button>
        )}
        {!isMobile && (
          <Tooltip title="Give Feedback" placement="left">
            <Button
              onClick={handleFeedbackClick}
              variant="contained"
              sx={{
                position: "fixed",
                bottom: "50%",
                right: 0,
                backgroundColor: "#d95ed0",
                color: "white",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#e48ede",
                },
                rotate: "270deg",
              }}
            >
              <FeedbackIcon sx={{ mr: 1 }} />
              Feedback
            </Button>
          </Tooltip>
        )}
      </Box>
      {getActiveMenuContent()}
    </>
  );
};

export default MainContent;

import { alpha } from "@mui/material/styles";
const gradient = "linear-gradient(to left, #C919BC 52%, #6E078E)";

export const SignRingGradient =
  "linear-gradient(to left, #C919BC 52%, #6E078E)";
export const changeAdressButtonStyle = {
  background: SignRingGradient,
  "&:hover": {
    backgroundColor: "#115293",
  },
  marginBottom: "20px",
};
export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  maxWidth: "80%", // Prevents the modal from being too wide on small screens
  bgcolor: "background.paper",
  border: "1px solid purple",
  borderRadius: "15px", // Rounded corners like the previous component
  boxShadow: 24,
  p: 4,
  color: "white",
  backgroundColor: alpha("#1e1e2f", 0.6),
  textAlign: "center",
};

export const boxStyle = {
  display: "flex", // Use flexbox for inline elements
  alignItems: "center", // Align items vertically
  justifyContent: "space-between", // Space out address and check button
  mb: 3,
};

export const checkButtonStyle = {
  textTransform: "none",
  // Green when valid, otherwise blue
  "&:hover": {
    // Maintain the green color if the address is valid, otherwise allow hover effect
    background: "#4CAF50",
  },

  color: "white",
  borderRadius: "20px",
  marginLeft: "10px",
  padding: "10px 20px",
};

export const ringItButtonStyle = {
  textTransform: "none",
  display: "block", // Make the button a block to center it below the address
  margin: "0 auto", // Auto margins for horizontal centering
  borderRadius: "20px", // Rounded corners for the button
  mt: 2, // Margin top to separate from the address section
  width: "50%", // Button takes half themodal's width
};

export const verifyButtonStyle = {
  background: gradient,
  display: "block",
  margin: "0 auto",
  mt: 2,
  textTransform: "none",
  "&:hover": {
    background: "linear-gradient(to left, #e740db 52%, #C919BC)",
  },
  "&.Mui-disabled": {
    background: "#32344A",
    color: "#fff",
  },
};

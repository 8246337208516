import React from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
import { modalStyle, boxStyle, ringItButtonStyle } from "../style";

const MintRequestModal: React.FC<{
  openModal: boolean;
  provingAddress: string;
  handleMintClick: () => void;
  isAddressCorrect: boolean;
  isLoading: boolean;
}> = ({
  openModal,
  provingAddress,
  handleMintClick,
  isAddressCorrect,
  isLoading,
}) => {
  return (
    <Modal
      aria-labelledby="mint-modal-title"
      aria-describedby="mint-modal-description"
      open={openModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={openModal}>
        <Box sx={modalStyle}>
          <Typography id="transition-modal-title" variant="h6" sx={{ mb: 2 }}>
            <b>Switch in GemWallet to the following Address:</b>
          </Typography>
          <Box sx={boxStyle}>
            <Typography sx={{ flexGrow: 1 }}>{provingAddress}</Typography>
          </Box>
          <Button
            variant="contained"
            onClick={handleMintClick}
            disabled={!isAddressCorrect || isLoading}
            sx={{
              background:
                isAddressCorrect && !isLoading
                  ? "linear-gradient(to left, #e740db 52%, #C919BC)"
                  : "gray",
              "&:hover": {
                background:
                  isAddressCorrect && !isLoading
                    ? "linear-gradient(to left, #e740db 52%, #C919BC)"
                    : "gray",
              },
              ...ringItButtonStyle,
              position: "relative",
            }}
          >
            {isLoading ? (
              <>
                <CircularProgress
                  size={24}
                  sx={{
                    color: "purple",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
                Loading...
              </>
            ) : (
              "Mint"
            )}
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

export default MintRequestModal;

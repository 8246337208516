/**
 * Convert an xrp value to drops
 *
 * @param xrp - xrp value to convert
 *
 * @returns - The corresponding drops value
 */
export function dropsFromXrp(xrp: Number | string): bigint {
  const xrpDecimals = 6;
  const strXrp: string = typeof xrp === "string" ? xrp : xrp.toString();
  const [integer, decimals] = strXrp.split(".");
  if (!decimals) {
    return BigInt(integer) * BigInt(10 ** xrpDecimals);
  }
  if (decimals.length > xrpDecimals) {
    throw new Error(`Invalid xrp value: ${xrp} -> too many decimals`);
  }
  const decimalsPadded = decimals.padEnd(xrpDecimals, "0");
  return BigInt(`${integer}${decimalsPadded}`);
}

import { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { formContainerStyle } from "../style";
import { useWalletInfo } from "../../../context/walletInfo";
import { useProofInfo } from "../../../context/proofInfo";
import { useNetworkInfo } from "../../../context/networkInfo";
import { fetchNFTs } from "../../../utils";
import ProofTable from "../../proofComponents/ProofTable";

const Overview = () => {
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [lastWalletAddress, setLastWalletAddress] = useState<string | null>(
    null,
  );
  const [lastNetworkInfo, setLastNetworkInfo] = useState<any | null>(null);
  const [lastProofInfo, setLastProofInfo] = useState<any | null>(null);

  const { userWalletInfo } = useWalletInfo();
  const { userNetworkInfo } = useNetworkInfo();
  const { userProofInfo } = useProofInfo();
  const hasChanged = (current: any, previous: any) => {
    return JSON.stringify(current) !== JSON.stringify(previous);
  };

  async function handleFetchNFT(proverAddress: string) {
    setIsFetching(true);
    const nftsData = await fetchNFTs(proverAddress, userNetworkInfo);
    setSearchResults(
      nftsData.filter((element): element is any => element !== null),
    );
    setIsFetching(false);
  }

  useEffect(() => {
    if (
      userWalletInfo?.address &&
      (hasChanged(userWalletInfo.address, lastWalletAddress) ||
        hasChanged(userNetworkInfo, lastNetworkInfo) ||
        hasChanged(userProofInfo, lastProofInfo))
    ) {
      handleFetchNFT(userWalletInfo.address);
      setLastWalletAddress(userWalletInfo.address);
      setLastNetworkInfo(userNetworkInfo);
      setLastProofInfo(userProofInfo);
    }
  }, [userWalletInfo, userNetworkInfo, userProofInfo]);

  return (
    <Box sx={formContainerStyle}>
      {userWalletInfo?.address ? (
        isFetching ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100px"
          >
            <CircularProgress sx={{ color: "#6E078E" }} />
          </Box>
        ) : searchResults.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100px"
          >
            <Typography sx={{ color: "#FFFFFF", textAlign: "center" }}>
              No proofs to display. Please check again later or verify that your
              proofs have been issued. If you think there is an error, contact
              the team on Discord.
            </Typography>
          </Box>
        ) : (
          <ProofTable searchResults={searchResults} />
        )
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100px"
        >
          <Typography
            sx={{
              color: "#FFFFFF", // Adjust the color as needed
              textAlign: "center",
            }}
          >
            Please connect with GemWallet first
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Overview;

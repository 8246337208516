import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { xrpFromDrops } from "../../../utils";
import { POAP_EXPLORER_BASE_URL, XRPL_EXPLORER_BASE_URL } from "../../../constant";
import { RingSignature } from "@cypher-laboratory/alicesring-sag";

const RingDetailsAccordion: React.FC<{
  expanded: string | false;
  handleChange: (
    panel: string,
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  ring: string[];
  nftDataFromIPFS: any;
}> = ({ expanded, handleChange, ring, nftDataFromIPFS }) => {

  const [eventData, setEventData] = React.useState<string>("Loading...");
  const [eventId, setEventId] = React.useState<string>("0");

  useEffect(() => {
    try {
      if (!nftDataFromIPFS?.additional.signature) throw new Error("No signature found in nftDataFromIPFS?.additional");

      const signedMsg = RingSignature.fromBase64(nftDataFromIPFS?.additional.signature);

      const message = JSON.parse(signedMsg.getMessage()) as any;

      setEventId(message.eventId);
      (async () => {
        const eventLoaded = await fetch(
          `https://api.poap.tech/metadata/${message.eventId}/1`,
        );

        if (!eventLoaded.ok) {
          setEventData("Error fetching event data. EventId: " + message.eventId);
          return;
        }

        const jsonEventLoaded = await eventLoaded.json();

        setEventData(jsonEventLoaded.image_url);
      })();
    } catch (error) {
      console.error("Error fetching event data: ", error);
      setEventData("Error fetching event data. EventId: " + eventId);
    }
  }, []);

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      sx={{
        backgroundColor: "#1A1A1A",
        color: "#FAF9F6",
        boxShadow: "none",
        width: "100%",
        maxWidth: "600px",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#FAF9F6" }} />}
        aria-controls="ring-details-content"
        id="ring-details-header"
        sx={{
          justifyContent: "center",
          "& .MuiAccordionSummary-content": {
            justifyContent: "center",
          },
        }}
      >
        <Typography sx={{ textAlign: "center", flexBasis: "100%" }}>
          Ring Details
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Collapse in={expanded === "panel1"} timeout="auto" unmountOnExit>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "240px",
              height: "240px",
              borderRadius: "50%",
              border: "2px solid purple",
              backgroundColor: "transparent",
              marginLeft: "23%",
              backgroundImage: `url(${eventData})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                color: "#FAF9F6",
                fontWeight: "bold",
                fontSize: "1.25rem",
                zIndex: 2,
              }}
            >
              {nftDataFromIPFS?.additional.currency === "XRP" && (
                xrpFromDrops(
                  nftDataFromIPFS
                    ? BigInt(nftDataFromIPFS.additional.minimumAmount)
                    : BigInt(0),
                )
              )}

              {nftDataFromIPFS?.additional.currency === "XRP" && (" ")}
              {nftDataFromIPFS?.additional.currency === "XRP" && (nftDataFromIPFS?.additional.currency)}
            </Typography>
            {ring?.map((address, index, arr) => {
              const angle = (360 / arr.length) * index;
              const rotation = {
                transform: `rotate(${angle}deg) translate(120px) rotate(-${angle}deg)`,
                transformOrigin: "0 50%",
              };

              const positionStyle = {
                ...rotation,
                position: "absolute",
                whiteSpace: "nowrap",
                display: "flex",
                alignItems: "center",
                borderMargin: "10px",
                "&:hover": {
                  transform: `scale(1.1) ${rotation.transform}`,
                  transition: "transform 0.2s ease-in-out",
                },
              };

              return (
                <Box key={index} sx={{ ...positionStyle }}>
                  <a
                    href={nftDataFromIPFS?.additional.currency === "XRP" ? `${XRPL_EXPLORER_BASE_URL}${address}` : `${POAP_EXPLORER_BASE_URL}${address}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "none",
                      color: "#FAF9F6",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "0.875rem",
                      }}
                    >
                      {`${address.substring(0, 6)}...${address.substring(
                        address.length - 4,
                      )}`}
                    </Typography>
                  </a>
                </Box>
              );
            })}
          </Box>
        </Collapse>
      </AccordionDetails>
    </Accordion>
  );
};

export default RingDetailsAccordion;

import { acceptNFTOffer, isInstalled } from "gemwallet-custom-api";

/**
 * Send a payment on xrpl using gem wallet
 *
 * @param amount - The amount to send (in the currency of the destination) (in the smallest unit)
 * @param destination - The destination address
 *
 * @returns The transaction id
 */
export async function acceptNFT(offer_id: string): Promise<string> {
  if ((await isInstalled()).result?.isInstalled) {
    if (!offer_id) throw new Error("Offer id is required");
    try {
      const acceptOfferTx = await acceptNFTOffer({
        NFTokenSellOffer: offer_id,
      });
      if (acceptOfferTx.result?.hash) {
        return acceptOfferTx.result?.hash;
      }
      throw new Error(
        "Something went wrong while sending payment from gemWallet",
      );
    } catch (e: any) {
      console.error("Something went wrong: ", e);
      throw new Error(
        "Something went wrong while sending payment from gemWallet: ",
      );
    }
  }
  throw new Error("Something went wrong while sending payment from gemWallet");
}

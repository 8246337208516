import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Button,
  MenuItem,
  FormControl,
  Select,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
  Tooltip,
  IconButton,
  InputLabel,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useWalletInfo } from "../../../context/walletInfo";
import { useNetworkInfo } from "../../../context/networkInfo";
import RingGeneration from "../../API/RingGeneration";
import { AlertColor } from "@mui/material/Alert";
import { RingGenerationResult } from "../../../interfaces";
import {
  CUSTOM_MESSAGE_MAX_MESSAGE_LENGTH,
  CYPHERLAB_XRPL_XRPLSOLVENCY_RECEIVING_ADDRESS,
  FREE_TIER_PAYMENT_ID,
  MAX_RING_SIZE,
  MIN_RING_SIZE,
  SupportedCurrencies,
  SupportedCurve,
  pricing,
} from "../../../constant";
import {
  dropsFromXrp,
  getAccountBalance,
  payWithGem,
  xrpFromDrops,
} from "../../../utils";
import {
  privacyDepthStyle,
  formContainerStyle,
  amountInputStyle,
  fieldContainerStyle,
  noteStyle,
  currencySelectStyle,
  priceAndButtonContainerStyle,
  priceStyle,
  buttonStyle,
  selectPoapStyle,
} from "../style";
import { getPoaps } from "../../../utils/poaps/getPoaps";
import { getPoapHolders } from "../../../utils/poaps/getPoapHolders";
import { Point } from "@cypher-laboratory/alicesring-sag";

// Utility Functions
const isValidRingSize = (size: number) =>
  size >= MIN_RING_SIZE && size <= MAX_RING_SIZE;
const isInteger = (value: string) => /^\d+$/.test(value);

const commonStyles = {
  color: "white",
  borderColor: "white",
  fontSize: "1rem",
  fontFamily: "DM Sans, sans-serif",
};

const hoverStyles = {
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#b52ab5",
  },
};

const Forms: React.FC<{
  onValidated: () => void;
  onApiResult: (result: any) => void;
}> = ({ onValidated, onApiResult }) => {
  const { userWalletInfo } = useWalletInfo();
  const { userNetworkInfo } = useNetworkInfo();

  const [owningAddress, setOwningAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [userPoaps, setUserPoaps] = useState<
    { eventId: string; tokenId: string; poapEvent: { eventName: string; description: string }; tokenUri: string }[] | null
  >(null);
  const [selectedPoap, setSelectedPoap] = useState<string | undefined>(undefined);
  const [crypto, setCrypto] = useState(String(SupportedCurrencies.XRP));
  const [ringSize, setRingSize] = useState(500);
  const [customMessage, setCustomMessage] = useState("");
  const [price, setPrice] = useState(BigInt(0));
  const [paymentId, setPaymentId] = useState<string | undefined>(undefined);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    AlertColor | undefined
  >(undefined);

  useEffect(() => {
    if (crypto === SupportedCurrencies.POAP) {
      setIsFormFilled(
        owningAddress !== "" &&
        selectedPoap !== undefined
      );
      return;
    }

    setIsFormFilled(
      owningAddress !== "" &&
      amount !== "" &&
      crypto !== "" &&
      isValidRingSize(ringSize)
    );
  }, [owningAddress, amount, crypto, ringSize, selectedPoap]);

  useEffect(() => {
    if (!isValidRingSize(ringSize) || !crypto || !amount) {
      setPrice(BigInt(0));
      return;
    }

    setPrice(
      pricing({
        currency: crypto as SupportedCurrencies,
        ringSize,
        customMessage,
      })
    );
  }, [crypto, ringSize, customMessage, amount]);

  useEffect(() => {
    (async () => {
      // check if the owningAddress is a valid ethereum address
      if (!/^0x[a-fA-F0-9]{40}$/.test(owningAddress) && crypto === SupportedCurrencies.POAP) {
        // setCrypto(SupportedCurrencies.XRP);
        // enable alert: A valid Ethereum Address is required in the Secret Address field

        setSnackbarMessage("A valid Ethereum Address is required in the Secret Address field");
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);

        setUserPoaps([]);

        // close the alert after 6 seconds
        setTimeout(() => {
          setSnackbarOpen(false);
        }, 6000);

        return;
      }
      // if crypto === SupportedCurrencies.POAP, get the users poap from the backend
      if (crypto === SupportedCurrencies.POAP) {
        setUserPoaps(null);
        // get the users poaps
        const poaps = await getPoaps(owningAddress);
        if (!poaps) {
          setUserPoaps(poaps);
          return;
        }

        const poapsWithImageLink = await Promise.all(poaps.map(async (poap) => {
          let uri = poap.tokenUri;
          // if(uri)
          try {
            uri = (await fetch(poap.tokenUri).then((res) => res.json())).image_url;
          } catch (error) {
            // console.error("Error fetching POAP image:", error);
          }

          return {
            ...poap,
            tokenUri: uri,
          };
        }));

        setUserPoaps(poapsWithImageLink ?? null);
      }
    })();
  }, [crypto, owningAddress]);

  const handleRingSizeChange = (value: string) => {
    if (value === "") {
      setRingSize(0);
      return;
    }

    if (!isInteger(value)) {
      setSnackbarMessage("Privacy depth must be an integer");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    setRingSize(parseInt(value));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmitRingRequest = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (!isValidRingSize(ringSize)) {
      setSnackbarMessage(
        `Privacy depth must be between ${MIN_RING_SIZE} and ${MAX_RING_SIZE}`
      );
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }
    if (
      crypto === SupportedCurrencies.XRP &&
      (await getAccountBalance(owningAddress, userNetworkInfo)) < BigInt(amount)
    ) {
      console.log("Insufficient balance: ", await getAccountBalance(owningAddress, userNetworkInfo), "expected: ", BigInt(amount));
      setSnackbarMessage("Insufficient balance");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    if (isFormFilled && !paymentId) {
      setSnackbarOpen(false);
      setIsLoading(true);

      try {
        const newPaymentId =
          price > BigInt(0)
            ? await payWithGem(
              price,
              CYPHERLAB_XRPL_XRPLSOLVENCY_RECEIVING_ADDRESS,
              crypto.toUpperCase()
            )
            : FREE_TIER_PAYMENT_ID;
        setPaymentId(newPaymentId);
      } catch (error) {
        setIsLoading(false);
        setSnackbarMessage("Error making payment: " + error);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }

      setIsLoading(false);
      return;
    }

    if (isFormFilled && paymentId) {
      setSnackbarOpen(false);
      setIsLoading(true);
      if (crypto === SupportedCurrencies.POAP && selectedPoap) {
        const ring = await getPoapHolders(selectedPoap);

        if (!ring) {
          setSnackbarMessage("Error fetching POAP holders");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setIsLoading(false);
          return;
        }

        setSnackbarMessage(
          `Ring successfully generated for poap ${selectedPoap}`
        );

        setSnackbarSeverity("success");
        onValidated();

        const ringGenerationResult: RingGenerationResult = {
          ring: ring.map((holder) => Point.deserialize(holder.slice(2))),
          crypto,
          eventId: selectedPoap,
          provingAddress: userWalletInfo.address,
          paymentId,
          curve: SupportedCurve.secp256k1,
          owningAddress,
          customMessage,
        };

        onApiResult(ringGenerationResult);

      } else {
        await RingGeneration({
          provingAddress: userWalletInfo.address,
          owningAddress,
          amount: dropsFromXrp(amount).toString(),
          crypto,
          ringSize,
          network: userNetworkInfo,
          paymentId,
          customMessage,
          onSuccess: (result: RingGenerationResult) => {
            setSnackbarMessage(
              "Ring successfully generated: " +
              JSON.stringify({
                token: result.crypto,
                amount: result.amount,
                curve: result.curve,
                compressedRing: result.ring.map((point) => point.toString()),
              })
            );
            setSnackbarSeverity("success");
            onValidated();
            onApiResult(result);
          },
          onError: (error) => {
            setSnackbarMessage("Error: " + error);
            setSnackbarSeverity("error");
          },
        }).finally(() => {
          setIsLoading(false);
          setSnackbarOpen(true);
        });
        setIsLoading(false);
        return;
      }
    }

    setIsLoading(false);
    return;
  };

  const handleChangeOwningAddress = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value !== userWalletInfo.address) {
      setOwningAddress(e.target.value);
    } else {
      alert(
        "Secret Reference Address cannot be the same as Proof Issuer Address"
      );
    }
  };

  const handleChangeCustomMessage = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (
      Buffer.from(e.target.value).length <= CUSTOM_MESSAGE_MAX_MESSAGE_LENGTH
    ) {
      setCustomMessage(e.target.value);
    } else {
      alert("Custom message exceeds the maximum allowed length.");
    }
  };

  const selectTokens = (onHover = true) => {
    return (
      <Select
        value={crypto}
        onChange={(e) => setCrypto(e.target.value)}
        sx={onHover ? { ...commonStyles, ...hoverStyles } : commonStyles}
        MenuProps={{
          PaperProps: {
            style: {
              backgroundColor: "#32344A",
            },
          },
        }}
      >
        {Object.keys(SupportedCurrencies).map((token) => (
          <MenuItem
            key={token}
            value={token}
            style={{ color: "white", backgroundColor: "#32344A" }}
          >
            {token.toUpperCase()}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const handlePoapSelection = (e: any) => {
    setSelectedPoap(e.target.value as string);
  };

  return (
    <form onSubmit={handleSubmitRingRequest} style={{ color: "white" }}>
      <Box sx={formContainerStyle}>
        <Typography sx={{ color: "white", mb: 3 }}>
          prover address: {userWalletInfo.address}
        </Typography>
        <Box sx={fieldContainerStyle}>
          <TextField
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                Secret Address
                <Tooltip title="Enter the address that holds the fund. This address will be used in the ring signature.">
                  <IconButton size="small" sx={{ color: "white" }}>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            }
            value={owningAddress}
            onChange={handleChangeOwningAddress}
            InputLabelProps={{ style: commonStyles }}
            InputProps={{ style: commonStyles }}
            sx={{
              ...amountInputStyle,
              "&& .MuiOutlinedInput-root": {
                borderTopLeftRadius: "25px",
                borderBottomLeftRadius: "25px",
                borderTopRightRadius: crypto === String(SupportedCurrencies.XRP) ? 0 : "25px",
                borderBottomRightRadius: crypto === String(SupportedCurrencies.XRP) ? 0 : "25px",
                "& > fieldset": { borderColor: "#b52ab5" },
              },
            }}
          />

          {
            crypto === String(SupportedCurrencies.XRP) && (
              <TextField
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    Privacy Depth
                    <Tooltip title="This is the number of addresses that will be used in the ring signature.">
                      <IconButton size="small" sx={{ color: "white" }}>
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                }
                value={ringSize === 0 ? "" : ringSize}
                onChange={(e) => handleRingSizeChange(e.target.value)}
                InputLabelProps={{ style: commonStyles }}
                InputProps={{ style: commonStyles }}
                sx={{
                  ...privacyDepthStyle,
                  "&& .MuiOutlinedInput-root": {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderTopRightRadius: "25px",
                    borderBottomRightRadius: "25px",
                    "& > fieldset": { borderColor: "#b52ab5" },
                  },
                }}
                type="number"
              />
            )}
        </Box>
        {crypto === String(SupportedCurrencies.XRP) ? (
          // Solvency proof
          <div>
            <Box sx={fieldContainerStyle}>
              <TextField
                label="Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                sx={{
                  ...amountInputStyle,
                  "&& .MuiOutlinedInput-root": {
                    borderTopLeftRadius: "25px",
                    borderBottomLeftRadius: "25px",
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    "& > fieldset": { borderColor: "#b52ab5" },
                  },
                }}
                type="number"
                InputLabelProps={{ style: commonStyles }}
                InputProps={{ style: commonStyles, ...hoverStyles }}
              />
              <FormControl
                sx={{
                  ...currencySelectStyle,
                  "&& .MuiOutlinedInput-root": {
                    "& > fieldset": { borderColor: "#b52ab5" },
                    borderTopRightRadius: "25px",
                    borderBottomRightRadius: "25px",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderLeft: 0,
                  },
                }}
              >
                {selectTokens()}
              </FormControl>
            </Box>
          </div>
        ) : (
          // attendance proof
          <div>
            <Typography sx={{ color: "white", mb: 2 }}>
              Select a POAP to prove ownership of:
            </Typography>

            <div>
              <Box sx={{ ...fieldContainerStyle, border: "1px solid purple" }}>
                {userPoaps === null ? (
                  <Typography sx={{ color: "white", ...amountInputStyle }}>Loading...</Typography>
                ) : userPoaps.length === 0 ? (
                  <Typography sx={{ color: "white", ...amountInputStyle }}>No POAPs found</Typography>
                ) : (
                  <>
                    <Select
                      value={selectedPoap ?? ""}
                      onChange={(e) => handlePoapSelection(e)}
                      displayEmpty
                      sx={{
                        ...selectPoapStyle,
                        "&& .MuiOutlinedInput-root": {
                          borderTopLeftRadius: "25px",
                          borderBottomLeftRadius: "25px",
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          "& > fieldset": { borderColor: "#b52ab5" },
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            backgroundColor: "#32344A",
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em style={{ color: "white" }}>Select a POAP</em>
                      </MenuItem>
                      {userPoaps.map((poap) => (
                        <MenuItem
                          key={poap.tokenId}
                          value={poap.eventId}
                          style={{ color: "white", backgroundColor: "#32344A" }}
                        >
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar src={poap.tokenUri} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body1"
                                  style={{
                                    color: "white",
                                    fontSize: "1.1rem",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "100%"
                                  }}
                                >
                                  {poap.poapEvent.eventName}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="body2"
                                  style={{
                                    color: "white",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "100%"
                                  }}
                                >
                                  {poap.poapEvent.description}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                <FormControl
                  sx={{
                    ...currencySelectStyle,
                    "&& .MuiOutlinedInput-root": {
                      // "& > fieldset": { borderColor: "#b52ab5" },
                      borderTopRightRadius: "25px",
                      borderBottomRightRadius: "25px",
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderLeft: 0,
                    },
                  }}
                >
                  {selectTokens(false)}
                </FormControl>
              </Box>
            </div>
          </div>
        )}
        <TextField
          label="Custom message (Optional)"
          value={customMessage}
          onChange={handleChangeCustomMessage}
          margin="normal"
          InputLabelProps={{ style: commonStyles }}
          InputProps={{ style: commonStyles }}
          sx={{
            ...amountInputStyle,
            "&& .MuiOutlinedInput-root": {
              borderTopLeftRadius: "25px",
              borderBottomLeftRadius: "25px",
              borderTopRightRadius: "25px",
              borderBottomRightRadius: "25px",
              background: "#32344A",
              "& > fieldset": { borderColor: "#b52ab5" },
            },
          }}
        />

        <Box sx={priceAndButtonContainerStyle}>
          <Box sx={priceStyle}>
            <Box sx={priceStyle}>
              {typeof paymentId === "undefined" && (
                <Typography sx={{ color: "white", mr: 2 }}>
                  <b>Price to pay:</b> {xrpFromDrops(price).toString()}{" "}
                  {crypto.toUpperCase()}
                </Typography>
              )}
            </Box>
          </Box>
          <Button
            type="submit"
            variant="contained"
            disabled={!isFormFilled || isLoading}
            sx={{
              ...buttonStyle,
              "&:hover": {
                background: "linear-gradient(to left, #e740db 52%, #C919BC)",
              },
              "&.Mui-disabled": {
                background: "#32344A",
                color: "#fff",
              },
            }}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : paymentId ? (
              "Generate Ring"
            ) : (
              "Pay"
            )}
          </Button>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbarSeverity}
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>

        {!paymentId && (
          <Typography sx={noteStyle}>
            Note: Once the payment is made, {crypto === "XRP" ? "the amount to prove" : "the selected POAP"}, the privacy
            depth, and the custom message cannot be changed.
          </Typography>
        )}
      </Box>
    </form>
  );
};

export default Forms;

import { useState } from "react";
import { TextField, Button, Box, CircularProgress } from "@mui/material";
import { fetchNFTs } from "../../../utils";
import {
  verifyButtonStyle,
  amountInputStyle,
  formContainerStyle,
} from "../style";
import ProofTable from "../../proofComponents/ProofTable";
import { useNetworkInfo } from "../../../context/networkInfo";

const VerifyProofComponent = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const { userNetworkInfo } = useNetworkInfo();
  const [isFetching, setIsFetching] = useState(false);

  const commonStyles = {
    color: "white",
    borderColor: "white",
    fontSize: "1rem",
    fontFamily: "DM Sans, sans-serif",
  };

  async function handleFetchNFT(proverAddress: string) {
    setIsFetching(true);
    const nftsData = await fetchNFTs(proverAddress, userNetworkInfo);
    setSearchResults(nftsData);
    setIsFetching(false);
  }

  const handleSearch = () => {
    handleFetchNFT(searchTerm);
  };

  return (
    <>
      <Box sx={formContainerStyle}>
        <TextField
          label="Prover Address"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputLabelProps={{ style: { ...commonStyles } }}
          InputProps={{ style: { ...commonStyles } }}
          sx={{
            ...amountInputStyle,
            "&& .MuiOutlinedInput-root": {
              borderTopLeftRadius: "25px",
              borderBottomLeftRadius: "25px",
              borderTopRightRadius: "25px",
              borderBottomRightRadius: "25px",
              background: "#32344A",
              "& > fieldset": { borderColor: "#b52ab5" },
            },
          }}
        />
        <Button
          variant="contained"
          onClick={handleSearch}
          disabled={isFetching}
          sx={{
            ...verifyButtonStyle,
          }}
        >
          {isFetching ? (
            <CircularProgress size={24} style={{ color: "white" }} />
          ) : (
            "Fetch proofs"
          )}
        </Button>
        {searchResults.length === 0 ? (
          <> No proofs to display. </>
        ) : (
          <ProofTable searchResults={searchResults}></ProofTable>
        )}
      </Box>
    </>
  );
};

export default VerifyProofComponent;

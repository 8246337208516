import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, Button, Snackbar, Alert } from "@mui/material";
import {
  Message,
  RingGenerationResult,
  SigningResult,
} from "../../../interfaces";
import {
  ringSignWithGemWallet,
  getLatestXrplBlockNumber,
} from "../../../utils";
import { RingSignature, sortRing } from "@cypher-laboratory/alicesring-sag";
import { AUTO_VERIFY_RING_SIG } from "../../../constant";
import {
  modalStyle,
  boxStyle,
  SignRingGradient,
  ringItButtonStyle,
} from "../style";
import { useWalletInfo } from "../../../context/walletInfo";
import { useNetworkInfo } from "../../../context/networkInfo";
import { getAddresses, importAccount, installSnap, SAG_signature } from "@cypher-laboratory/alicesring-snap-sdk";

interface SignRingProps {
  onValidated: () => void;
  data: RingGenerationResult | null;
  onRingSignResult: (result: SigningResult) => void;
}

const SignRing: React.FC<SignRingProps> = ({
  onValidated,
  data,
  onRingSignResult,
}) => {
  const [isAddressCorrect, setIsAddressCorrect] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { userWalletInfo } = useWalletInfo();
  const { userNetworkInfo } = useNetworkInfo();

  const [connectingSnap, setConnectingSnap] = useState(false);
  const [addressFountInSnap, setAddressFoundInSnap] = useState<boolean | null>(null);
  const [isImportingInSnap, setIsImportingInSnap] = useState(false);

  useEffect(() => {
    if (data && data.crypto !== "POAP") {
      const isCorrect = userWalletInfo.address === data.owningAddress;
      setIsAddressCorrect(isCorrect);
      setSnackbarMessage(
        isCorrect
          ? "Address is correct."
          : "The current address in GemWallet does not match the required Secret Address.",
      );
      setSnackbarOpen(true);
    } else {
      setIsAddressCorrect(true);
    }
  }, [userWalletInfo, data]);

  const handleRingClick = useCallback(async () => {
    if (!data) return;

    try {
      const lastLedger = await getLatestXrplBlockNumber(userNetworkInfo);
      if (data.crypto === "POAP") {
        const message = JSON.stringify({
          currency: "POAP",
          eventId: data.eventId,
          validAtBlock: lastLedger.toString(),
          network: userNetworkInfo,
          message: data.customMessage ?? "",
          proverAddress: data.owningAddress,
        });

        const signature = await SAG_signature(sortRing(data.ring).map((point) => point.serialize()), message, data.owningAddress);

        if (signature === "" || signature === undefined) throw new Error("Signature error");

        onRingSignResult({
          paymentId: data.paymentId,
          ringSignature: signature,
          provingAddress: data.provingAddress,
        });
      } else {
        if (data.amount === undefined) throw new Error("Amount is required");

        const message: Message = {
          currency: data.crypto.toUpperCase(),
          amount: data.amount,
          validAtBlock: lastLedger.toString(),
          network: userNetworkInfo,
          message: data.customMessage ?? "",
          proverAddress: data.provingAddress,
        };
        const ringData = {
          message,
          owningAddress: data.owningAddress,
          ring: data.ring.map((point) => point.toBase64()),
          curve: data.ring[0].curve.toString(),
        };
        const finalSignature = await ringSignWithGemWallet(ringData);
        if (AUTO_VERIFY_RING_SIG) {
          if (!RingSignature.verify(finalSignature)) {
            throw new Error(
              "Signing error: signature is not valid. Please retry.",
            );
          }
        }
        onRingSignResult({
          paymentId: data.paymentId,
          ringSignature: finalSignature,
          provingAddress: data.provingAddress,
        });
      }
      onValidated();
    } catch (error) {
      setSnackbarMessage(`Signing error: ${error}`);
      setSnackbarOpen(true);
    }
  }, [data, userNetworkInfo, onRingSignResult, onValidated]);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleConnectSnap = async () => {
    if (!data) return;

    setConnectingSnap(true);

    const installed = await installSnap();

    if (!installed) {
      const nowInstalled = await installSnap();
      if (!nowInstalled) {
        // alert("Error installing snap");
        return;
      }
    }

    // get the snap addresses
    const snapAddresses = await getAddresses();

    const found = snapAddresses.find((address) => address.toLowerCase() === data.owningAddress.toLowerCase()) !== undefined;
    setAddressFoundInSnap(found);

    if (!found) {
      setConnectingSnap(false);
      return;
    }
    setConnectingSnap(false);
  };

  const handleImportAccountInSnap = async () => {
    if (!data) return;

    setIsImportingInSnap(true);

    const result = await importAccount();

    if (!result) {
      // alert("Error importing account in snap");
      return;
    }

    // get the snap addresses
    const snapAddresses = await getAddresses();

    const found = snapAddresses.find((address) => address.toLowerCase() === data.owningAddress.toLowerCase()) !== undefined;
    setAddressFoundInSnap(found);

    setIsImportingInSnap(false);
  };

  if (!data) {
    return <Typography>Please complete the previous step first</Typography>;
  }

  return (
    <>
      <Box sx={modalStyle}>
        <Typography id="transition-modal-title" variant="h6" sx={{ mb: 2 }}>
          <b>{data.crypto === "XRP"
            ? "Switch in GemWallet to the following Address:"
            : "Connect the ring signature snap with the following Address:"
          }</b>
        </Typography>
        <Box sx={boxStyle}>
          <Typography id="transition-modal-description" sx={{ flexGrow: 1 }}>
            {data.owningAddress}
          </Typography>
        </Box>
        {
          data.crypto === "POAP" &&
          addressFountInSnap !== true &&
          // connect metamask snap button
          <>
            <Button
              variant="contained"
              onClick={addressFountInSnap === null ? handleConnectSnap : handleImportAccountInSnap}
              // disabled={!addressFountInSnap}
              sx={{
                background: SignRingGradient,
                "&:hover": {
                  background: "linear-gradient(to left, #e740db 52%, #C919BC)"
                },
                ...ringItButtonStyle,
              }}
            > {addressFountInSnap === null ? "Connect snap" : "import account in snap"}</Button>
          </>
        }
        {
          (data.crypto !== "POAP" ||
            addressFountInSnap === true) &&
          <Button
            variant="contained"
            onClick={handleRingClick}
            disabled={!isAddressCorrect}
            sx={{
              background: isAddressCorrect ? SignRingGradient : "gray",
              "&:hover": {
                background: isAddressCorrect
                  ? "linear-gradient(to left, #e740db 52%, #C919BC)"
                  : "gray",
              },
              ...ringItButtonStyle,
            }}
          >
            Ring it!
          </Button>
        }
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={isAddressCorrect ? "success" : "error"}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SignRing;

import React from "react";

const NetworkInfoContext = React.createContext();
export const useNetworkInfo = () => React.useContext(NetworkInfoContext);
export const NetworkInfoProvider = ({ children }) => {
  const [userNetworkInfo, setUserNetworkInfo] = React.useState("");

  return (
    <NetworkInfoContext.Provider
      value={{ userNetworkInfo, setUserNetworkInfo }}
    >
      {children}
    </NetworkInfoContext.Provider>
  );
};

import React, { useEffect, useState } from "react";
import { Box, Link, Typography, CircularProgress } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import { getLatestXrplBlockNumber } from "../utils";
import { useNetworkInfo } from "../context/networkInfo";

const Footer: React.FC = () => {
  const { userNetworkInfo } = useNetworkInfo();
  const [latestBlock, setLatestBlock] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLatestBlock = async () => {
      if (userNetworkInfo) {
        const blockNumber = await getLatestXrplBlockNumber(userNetworkInfo);
        setLatestBlock(blockNumber);
        setLoading(false);
      }
    };
    fetchLatestBlock();
  }, [userNetworkInfo]);

  return (
    <Box
      sx={{
        backgroundColor: "rgba(18, 18, 18, 0.2)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 20px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {[
          {
            href: "https://twitter.com/cypher_lab",
            icon: <TwitterIcon />,
          },
          {
            href: "https://fr.linkedin.com/company/cypher-lab",
            icon: <LinkedInIcon />,
          },
          {
            href: "https://github.com/Cypher-Laboratory",
            icon: <GitHubIcon />,
          },
        ].map((social, index) => (
          <Link
            key={index}
            href={social.href}
            target="_blank"
            color="inherit"
            sx={{
              margin: "0 15px",
              color: "white",
              fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
            }}
          >
            {social.icon}
          </Link>
        ))}
      </Box>

      {userNetworkInfo && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#A0F0E0", // pastel green color
          }}
        >
          <Typography
            variant="body2"
            component="span"
            sx={{
              marginRight: "10px",
              fontSize: "0.8rem", // smaller font size
            }}
          >
            {userNetworkInfo}
          </Typography>
          {loading ? (
            <CircularProgress size={20} sx={{ color: "#A0F0E0" }} />
          ) : (
            <Typography
              variant="body2"
              component="span"
              sx={{
                fontSize: "0.8rem", // smaller font size
                color: "#A0F0E0", // pastel green color
              }}
            >
              {latestBlock}
            </Typography>
          )}
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              backgroundColor: "green", // pastel green color
              marginLeft: 1,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Footer;

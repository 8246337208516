import React from "react";
import {
  Box,
  Container,
  CssBaseline,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import MainContent from "./Components/MainContent";
import theme from "./Components/theme";
import backgroundImage from "./Contents/Background/background_alice_ring.png";
import logo from "./Contents/Logo/Cypher_Lab_Alpha_W.png";
import { WalletInfoProvider } from "./context/walletInfo";
import { NetworkInfoProvider } from "./context/networkInfo";
import { ProofInfoProvider } from "./context/proofInfo";
import { GwInfoProvider } from "./context/gwInfo";
const App: React.FC = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  let fontSize;
  if (isSmallScreen) {
    fontSize = "2vh";
  } else if (isMediumScreen) {
    fontSize = "2.5vh";
  } else {
    fontSize = "3.5vh";
  }

  return (
    <ThemeProvider theme={theme}>
    {/* <button onClick={() => BigInt("jbkjbckjbbkjba")} >Click me</button> */}
      <GwInfoProvider>
        <WalletInfoProvider>
          <NetworkInfoProvider>
            <ProofInfoProvider>
              <CssBaseline />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "100vh",
                  backgroundImage: `url(${backgroundImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <Navbar logo={logo} />
                <Container component="main" sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "DM Sans, sans-serif",
                      fontSize: fontSize,
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <MainContent />
                  </Box>
                </Container>
                <Footer />
              </Box>
            </ProofInfoProvider>
          </NetworkInfoProvider>
        </WalletInfoProvider>
      </GwInfoProvider>
    </ThemeProvider>
  );
};

export default App;

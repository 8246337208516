import { Client } from "xrpl";
import { API_VERIFY_URL } from "../constant";
import { ProofData } from "../interfaces";
import { getWSSNetworkProvider } from "./getNetworkProvider";

export async function fetchNFTs(
  proverAddress: string,
  network: string,
): Promise<ProofData[]> {
  const xrplWssUrl = getWSSNetworkProvider(network);
  const client = new Client(xrplWssUrl);
  try {
    await client.connect();
    const response = await client.request({
      command: "account_nfts",
      account: proverAddress,
      ledger_index: "validated",
    });
    if (!response.result.account_nfts) {
      return [];
    }
    const nfts = response.result.account_nfts;

    const requestData = nfts.map((nft) => ({
      network: network,
      tokenId: nft.NFTokenID,
    }));

    const verifyResponse = await fetch(`${API_VERIFY_URL}/verifyBatch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    if (verifyResponse.ok) {
      const verificationResult = await verifyResponse.json();
      return verificationResult.verified;
    } else {
      console.error(
        "Error verifying proof for NFTs:",
        verifyResponse.statusText,
      );
      return [];
    }
  } catch (error) {
    console.error("Failed to fetch NFTs:", error);
    return [];
  } finally {
    await client.disconnect();
  }
}

import { alpha } from "@mui/material/styles";
export const gradient = "linear-gradient(to left, #C919BC 52%, #6E078E)";
export const customMessageStyle = {
  flexGrow: 1,
  "& .MuiOutlinedInput-root": {
    borderRadius: "25px", // Apply rounded corners to the input field
    backgroundColor: "#32344A", // Match the input field background color
    "& fieldset": {
      borderRadius: "25px", // Apply rounded corners to the fieldset as well
      borderColor: "transparent", // Make the border transparent by default
    },
    "&:hover fieldset": {
      borderColor: "#b52ab5", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#b52ab5", // Border color when the input is focused
    },
  },
  "& .MuiInputLabel-root": {
    color: "white", // Label color
    "&.Mui-focused": {
      color: "#b52ab5", // Label color when the input is focused
    },
  },
};

// Styles for the amount TextField
export const amountInputStyle = {
  flexGrow: 1,
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]": {
    "-moz-appearance": "textfield", // Firefox
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#b52ab5",
  },
};

export const selectPoapStyle = {
  flexGrow: 1,
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  // "& input[type=number]": {
  //   "-moz-appearance": "textfield", // Firefox
  // },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#b52ab5",
  },
  "& .MuiOutlinedInput-root": {
    borderLeft: "none", // Remove the left border
    borderRadius: "0 4px 4px 0", // Rounded corners on the right
  },
  width: "120px", // Adjust as needed
};

export const privacyDepthStyle = {
  "& .MuiOutlinedInput-root": {
    borderLeft: "none", // Remove the left border
    borderRadius: "0 4px 4px 0", // Rounded corners on the right
  },
  width: "120px",
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]": {
    "-moz-appearance": "textfield", // Firefox
  },
};

// Styles for the currency Select
export const currencySelectStyle = {
  "& .MuiOutlinedInput-root": {
    borderLeft: "none", // Remove the left border
    borderRadius: "0 4px 4px 0", // Rounded corners on the right
  },
  width: "120px", // Adjust as needed
};

export const formContainerStyle = {
  mb: 2,
  p: 2,
  border: "1px solid purple",
  borderRadius: "25px",
  backgroundColor: alpha("#1e1e2f", 0.6), // 40% opacity
  marginTop: "25px",
  marginBottom: "25px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  width: "50%", // Reduce the width by 50%
  mx: "auto", // Center the box horizontally
};

export const fieldContainerStyle = {
  display: "flex",
  alignItems: "center", // Ensure inner elements are aligned properly
  background: "#32344A", // Assuming this is the desired "fused" background color
  borderRadius: "25px",
};

export const hideNumberInputArrows = {
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]": {
    "-moz-appearance": "textfield", // Firefox
  },
};

export const priceAndButtonContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  mb: 2,
  mt: 2,
  flexDirection: "column", // Stack vertically on mobile views if needed
};

export const priceStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  mb: 1, // Margin bottom for spacing between price and button
};

export const buttonStyle = {
  textTransform: "none",
  background: gradient,
  color: "white",
  "&:hover": { background: gradient },
  fontSize: { xs: "0.8rem", sm: "1rem" },
};

export const noteStyle = {
  color: "white",
  fontSize: "0.8rem", // Smaller text for the note
  mt: 2, // Spacing from the above element
  textAlign: "center",
  width: "100%", // Ensure the note spans the full width
};

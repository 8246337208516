import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import WalletConnector from "./WalletConnector";
interface NavbarProps {
  logo: string; // URL or path to the logo
}

function isMobileDevice() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const isMobile = /mobile|android|iphone|ipad|phone/.test(userAgent);
  return isMobile;
}

const Navbar: React.FC<NavbarProps> = ({ logo }) => {
  const isMobile = isMobileDevice();
  const handleCheckWallet = (isInstalled: boolean) => {
    console.log("GemWallet is installed.");
  };

  let logoStyles;
  if (isMobile) {
    logoStyles = {
      maxWidth: "15vw",
      maxHeight: "8vh",
      marginRight: "2vh",
      marginLeft: "1vh",
      marginTop: "2vh",
      marginBottom: "2vh",
    };
  } else {
    logoStyles = {
      maxWidth: "20vw",
      maxHeight: "10vh",
      marginRight: "5vh",
      marginLeft: "2vh",
      marginTop: "4vh",
      marginBottom: "4vh",
    };
  }

  return (
    <AppBar position="static" sx={{ backgroundColor: "rgba(18, 18, 18, 0.2)" }}>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <img src={logo} alt="Cypher Lab" style={logoStyles} />
        {!isMobile && (
          <WalletConnector onInstallationCheck={handleCheckWallet} />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

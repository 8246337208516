import React, { useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  IPFS_READ_BASE_URL,
  NFT_STANDARD_METADATA,
  XRPL_EXPLORER_BASE_URL,
  POAP_DROP_EXPLORER_BASE_URL,
} from "../../../constant";
import { xrpFromDrops } from "../../../utils";
import RingDetailsAccordion from "./RingDetailsAccordion";
import {
  tableCellStyleBold,
  tableCellStyleNormal,
  tableRowStyle,
} from "./mintStyle";
import { changeAdressButtonStyle } from "../style";
import { RingSignature } from "@cypher-laboratory/alicesring-sag";

const MintRequestDetails: React.FC<{
  isClaimSuccessful: boolean;
  nftData: { mintTx: string; uri: string };
  nftDataFromIPFS: any;
  ring: string[];
  expanded: string | false;
  handleChange: (
    panel: string,
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  handleClaimClick: () => void;
  handleCloseClick: () => void;
}> = ({
  isClaimSuccessful,
  nftData,
  nftDataFromIPFS,
  ring,
  expanded,
  handleChange,
  handleClaimClick,
  handleCloseClick,
}) => {

    const [eventData, setEventData] = React.useState<{ img: string, name: string, description: string } | string>("Loading...");
    const [eventId, setEventId] = React.useState<string>("0");

    useEffect(() => {
      try {
        if (!nftDataFromIPFS?.additional.signature) throw new Error("No signature found in nftDataFromIPFS?.additional");

        const signedMsg = RingSignature.fromBase64(nftDataFromIPFS?.additional.signature);

        const message = JSON.parse(signedMsg.getMessage()) as any;

        setEventId(message.eventId);
        (async () => {
          const eventLoaded = await fetch(
            `https://api.poap.tech/metadata/${message.eventId}/1`,
          );

          if (!eventLoaded.ok) {
            setEventData("Error fetching event data. EventId: " + message.eventId);
            return;
          }

          const jsonEventLoaded = await eventLoaded.json();

          setEventData({ img: jsonEventLoaded.image_url, name: jsonEventLoaded.name, description: jsonEventLoaded.description });
        })();
      } catch (error) {
        console.error("Error fetching event data: ", error);
        setEventData("Error fetching event data. EventId: " + eventId);
      }
    }, []);

    const formatDisplayString = (str: any) => {
      if (str && str.length > 6) {
        return `${str.substring(0, 6)}...${str.substring(str.length - 6)}`;
      }
      return str;
    };

    return (
      <Box
        sx={{
          p: 2,
          border: "1px solid purple",
          borderRadius: "15px",
          textAlign: "center",
          color: "#FAF9F6",
          marginBottom: "10px",
          backgroundColor: "#1e1e2f",
          mx: "auto",
          maxWidth: "90vw",
          width: "100%",
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: "15px", mt: 0 }}>
          <b>
            {isClaimSuccessful
              ? "Proof claimed successfully"
              : 'Proof minted successfully, accept the NFT offer by clicking the "Claim" button'}
          </b>
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid
            item
            xs={12}
            sm={6}
            display="flex"
            justifyContent="center"
            marginTop="10px"
          >
            <img
              src={NFT_STANDARD_METADATA.image}
              alt="proof"
              style={{ width: "300px", height: "auto", borderRadius: "10px" }}
            />
          </Grid>

          <Grid item xs={12} sm={6} marginTop="10px">
            <TableContainer
              component={Paper}
              sx={{
                overflowX: "hidden",
                boxShadow: "none",
                width: "auto",
                backgroundColor: "#1A1A1A",
              }}
            >
              <Table aria-label="nft-data-table">
                <TableBody>
                  <TableRow sx={tableRowStyle}>
                    <TableCell sx={tableCellStyleBold}>Prover address</TableCell>
                    <TableCell sx={tableCellStyleNormal}>
                      {nftDataFromIPFS?.additional.proverAddress}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={tableRowStyle}>
                    {nftDataFromIPFS?.additional.currency === "XRP" && (
                      <>
                        <TableCell sx={tableCellStyleBold}>Proved amount</TableCell>
                        <TableCell sx={tableCellStyleNormal}>
                          {xrpFromDrops(
                            nftDataFromIPFS
                              ? BigInt(nftDataFromIPFS.additional.minimumAmount)
                              : BigInt(0),
                          )}{" "}
                          {nftDataFromIPFS?.additional.currency}
                        </TableCell>
                      </>
                    )}
                    {
                      nftDataFromIPFS?.additional.currency === "POAP" && (
                        <>
                          <TableCell sx={tableCellStyleBold}>Attendance Proved</TableCell>
                          <TableCell
                            sx={tableCellStyleNormal}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              // border: "2px solid #FAF9F6",
                              // borderRadius: "10px",
                              padding: "10px",
                              overflow: "hidden",
                              minWidth: "fit-content"
                            }}
                            onClick={() => window.open(`${POAP_DROP_EXPLORER_BASE_URL}${eventId}`, "_blank")}
                          >

                            <div style={{ border: "1px solid white", borderRadius: "10px", padding: "10px", display: "flex", alignItems: "center" }}>
                              {typeof eventData === "object" && (
                                <img
                                  src={eventData.img}
                                  alt="event"
                                  style={{ width: "50px", height: "50px", borderRadius: "10px", marginRight: "10px", flexShrink: 0 }}
                                />
                              )}
                              <Box sx={{ flexGrow: 1 }}>
                                {typeof eventData === "string" ? eventData : null}
                                {typeof eventData === "object" ? <div>{eventData.name}</div> : null}
                                {typeof eventData === "object" ? <div>{eventData.description}</div> : null}
                              </Box>
                            </div>
                          </TableCell>
                        </>
                      )
                    }
                  </TableRow>
                  <TableRow sx={tableRowStyle}>
                    <TableCell sx={tableCellStyleBold}>Ring size</TableCell>
                    <TableCell sx={tableCellStyleNormal}>
                      {nftDataFromIPFS?.additional.ringSize}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={tableRowStyle}>
                    <TableCell sx={tableCellStyleBold}>Mint TX</TableCell>
                    <TableCell sx={tableCellStyleNormal}>
                      {formatDisplayString(nftData.mintTx)}
                      <IconButton
                        onClick={() =>
                          window.open(
                            `${XRPL_EXPLORER_BASE_URL}${nftData.mintTx}`,
                            "_blank",
                          )
                        }
                      >
                        <LaunchIcon sx={{ color: "#FAF9F6" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={tableRowStyle}>
                    <TableCell sx={tableCellStyleBold}>IPFS URI</TableCell>
                    <TableCell sx={tableCellStyleNormal}>
                      {formatDisplayString(nftData.uri)}
                      <IconButton
                        onClick={() =>
                          window.open(
                            `${IPFS_READ_BASE_URL}${nftData.uri}`,
                            "_blank",
                          )
                        }
                      >
                        <LaunchIcon sx={{ color: "#FAF9F6" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} sm={6} marginTop="10px">
            <RingDetailsAccordion
              expanded={expanded}
              handleChange={handleChange}
              ring={ring}
              nftDataFromIPFS={nftDataFromIPFS}
            />
          </Grid>
        </Grid>

        {!isClaimSuccessful ? (
          <Button
            variant="contained"
            onClick={handleClaimClick}
            sx={{ ...changeAdressButtonStyle, marginTop: "15px" }}
          >
            Claim
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleCloseClick} // Use handleCloseClick
            sx={{ ...changeAdressButtonStyle, marginTop: "15px" }}
          >
            Dashboard
          </Button>
        )}
      </Box>
    );
  };

export default MintRequestDetails;

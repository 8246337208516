import { BNInput, ec, eddsa, curve } from "elliptic";
import { Point } from "@cypher-laboratory/alicesring-sag";
import BN from "bn.js";
import { getAddressFromSigningPubkey } from "./addressFromSigningPubkey";

/**
 * Converts an array of bytes to a hex string.
 * @param bytes the bytes to convert to hex.
 * @returns the hex string corresponding to the bytes.
 */
export function bytesToHex(
  bytes: Iterable<number> | ArrayLike<number>,
): string {
  return Array.from(bytes, (byteValue) => {
    const hex = byteValue.toString(16).toUpperCase();
    return hex.length > 1 ? hex : `0${hex}`;
  }).join("");
}

/**
 * Get the XRPL address from a pubkey as a Point.
 * @param point the point to get the address from.
 * @returns the XRPL address corresponding to the point.
 */
export function pointToXRPLAddress(point: Point): string {
  let encodedPukey: string;
  let a: BNInput;
  let b: BNInput;
  let p: curve.edwards.EdwardsPoint;
  let key: eddsa.KeyPair;
  let encodedPuke: string;
  switch (point.curve.name) {
    case "SECP256K1":
      const secp256k1 = new ec("secp256k1");
      encodedPukey = secp256k1.curve
        .pointFromX(point.x.toString(16))
        .encodeCompressed();
      return getAddressFromSigningPubkey(encodedPukey);

    case "ED25519":
      const ed25519 = new eddsa("ed25519");
      a = new BN(point.x.toString(16), 16);
      b = new BN(point.y.toString(16), 16);
      p = ed25519.curve.point(a, b);
      key = ed25519.keyFromPublic(p);

      encodedPuke = "ED" + bytesToHex(key.getPublic());
      return getAddressFromSigningPubkey(encodedPuke);

    default:
      throw new Error("Curve not supported");
  }
}

import {
  API_RING_GENERATION_URL,
  JSON_RPC_REQUEST_METHOD,
  SupportedCurrencies,
  SupportedCurve,
} from "../../constant";
import { P0, P0Data, P1, RingGenerationResult } from "../../interfaces";
import { Point } from "@cypher-laboratory/alicesring-sag";
import {
  getPublicKeysFromGem,
  pointFromHexXRPL,
  signWithGem,
} from "../../utils";

interface RingGenerationProps {
  provingAddress: string;
  owningAddress: string;
  amount: string;
  crypto: string;
  ringSize: number;
  network: string;
  paymentId: string;
  customMessage?: string;
  onSuccess: (result: RingGenerationResult) => void;
  onError: (error: string) => void;
}

// Define the return type for the promise
interface ReturnType {
  data: P0;
  curve: SupportedCurve;
}

function validateCrypto(
  crypto: string,
  onError: (error: string) => void,
): boolean {
  if (!Object.keys(SupportedCurrencies).includes(crypto)) {
    onError("Token not supported");
    return false;
  }
  return true;
}

async function prepareP0Data(
  provingAddress: string,
  amount: string,
  crypto: string,
  ringSize: number,
  network: string,
  paymentId: string,
  customMessage?: string,
): Promise<ReturnType> {
  const requestedRingSize = ringSize - 2; // - 1 is expected but idk why, if we use -1, we get a ring of n+1 when we ask for a ring of n
  const { gemPublicKey } = await getPublicKeysFromGem();
  const provingPublicKey = pointFromHexXRPL(gemPublicKey);
  const curve = gemPublicKey.startsWith("ED")
    ? SupportedCurve.ed25519
    : SupportedCurve.secp256k1;

  const p0Data: P0Data = {
    paymentId: paymentId,
    sigData: {
      ringSize: requestedRingSize,
      curve: curve,
      minBalance: amount,
      provingPublicKey: provingPublicKey.toString(),
      network: network,
      currency: SupportedCurrencies[crypto as keyof typeof SupportedCurrencies],
      customMessage: customMessage,
    },
  };

  const data: P0 = {
    type: JSON_RPC_REQUEST_METHOD.INIT_RING_GENERATION,
    data: p0Data,
    provingSignature: await signWithGem(JSON.stringify(p0Data), provingAddress),
  };

  return { data, curve };
}

async function handleApiResponse(
  apiResponse: Response,
  onError: (error: string) => void,
): Promise<P1 | null> {
  if (!apiResponse.ok) {
    onError("Error generating ring: " + apiResponse.statusText);
    return null;
  }

  const result: P1 = await apiResponse.json();
  if (result.error) {
    onError(result.error);
    return null;
  }

  if (
    !(
      result.data.status &&
      result.data.signatureId &&
      result.data.ring &&
      result.data.validAtBlock
    )
  ) {
    onError(
      "Error generating ring: missing data in response: received: " +
        result.data,
    );
    return null;
  }

  return result;
}

function decompressRing(
  ringData: string,
  requestedRingSize: number,
  onError: (error: string) => void,
): Point[] | null {
  try {
    const ring = ringData
      .split(",")
      .map((base64: string) => Point.fromBase64(base64));

    if (ring.length < requestedRingSize) {
      onError(`Ring size = ${ring.length} too small`);
      return null;
    }

    return ring;
  } catch (error) {
    console.error("Error decompressing ring: ", error);
    onError("Error decompressing ring: " + error);
    return null;
  }
}

async function RingGeneration({
  provingAddress,
  owningAddress,
  amount,
  crypto,
  ringSize,
  network,
  paymentId,
  customMessage,
  onSuccess,
  onError,
}: RingGenerationProps): Promise<void> {
  if (!validateCrypto(crypto, onError)) return;

  try {
    const { data, curve } = await prepareP0Data(
      provingAddress,
      amount,
      crypto,
      ringSize,
      network,
      paymentId,
      customMessage,
    );

    const apiResponse = await fetch(API_RING_GENERATION_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    const result = await handleApiResponse(apiResponse, onError);
    if (!result) return;

    const ring = decompressRing(result.data.ring!, ringSize - 1, onError);
    if (!ring) return;

    onSuccess({
      ring,
      crypto,
      amount,
      provingAddress,
      paymentId,
      curve: curve,
      owningAddress,
      customMessage,
    });
  } catch (error) {
    console.error("Error generating ring: ", error);
    onError("Error generating ring: " + error);
  }
}

export default RingGeneration;

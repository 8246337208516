import { useState, useEffect, forwardRef } from "react";
import {
  Grid,
  Typography,
  TableContainer,
  Paper,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Accordion,
  AccordionSummary,
  Button,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import { burnNFTGem } from "../../utils/gem/burnNFT";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { xrpFromDrops, pointToXRPLAddress } from "../../utils";
import { XRPL_EXPLORER_BASE_URL } from "../../constant";
import { RingSignature } from "@cypher-laboratory/alicesring-sag";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const tableRowStyle = {
  backgroundColor: "#1A1A1A",
  "&:hover": {
    backgroundColor: "#292929",
  },
  transition: "background-color 0.3s",
};

const tableCellStyle = {
  borderBottom: "none",
  padding: "16px",
  fontSize: "0.875rem",
  color: "#FAF9F6",
};

const accordionStyle = {
  backgroundColor: "#212121",
  color: "#FAF9F6",
  boxShadow: "none",
  borderRadius: "10px",
  marginBottom: "10px",
  "&:before": {
    display: "none",
  },
  "& .MuiAccordionSummary-root": {
    padding: "0 24px",
    minHeight: "48px",
    "&.Mui-expanded": {
      minHeight: "48px",
    },
    "& .MuiAccordionSummary-content": {
      margin: "12px 0",
      "&.Mui-expanded": {
        margin: "12px 0",
      },
    },
  },
  "& .MuiAccordionDetails-root": {
    padding: "16px 24px",
    borderTop: "1px solid rgba(255, 255, 255, 0.12)",
  },
};

const ProofDetails = forwardRef(({ nftData }: { nftData: any }, ref) => {
  const [ring, setRing] = useState<string[]>([]);

  useEffect(() => {
    const fetchRing = async () => {
      if (nftData?.signature) {
        const ringSig = RingSignature.fromBase64(nftData.signature);
        setRing(ringSig.getRing().map((p) => pointToXRPLAddress(p)));
      } else {
        console.log("No signature available or nftData is missing");
      }
    };
    fetchRing();
  }, [nftData]);

  const handleBurnClick = async (NFTokenID: string) => {
    try {
      await burnNFTGem(NFTokenID);
    } catch (error) {
      console.error("Error burning NFT: ", error);
    }
  };

  const renderTableRow = (label: string, value: string) => (
    <TableRow sx={tableRowStyle}>
      <TableCell sx={{ ...tableCellStyle, fontWeight: "bold" }}>
        {label}
      </TableCell>
      <TableCell sx={tableCellStyle}>{value}</TableCell>
    </TableRow>
  );

  return (
    <Grid item xs={12} md={6} sx={{ position: "relative" }}>
      <TableContainer
        component={Paper}
        sx={{
          overflow: "hidden",
          boxShadow: "none",
          width: "auto",
          maxWidth: "100%",
          backgroundColor: "#1A1A1A",
          marginBottom: "10px",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <Table aria-label="nft-data-table">
          <TableBody>
            {renderTableRow("Prover address", nftData?.proverAddress)}
            {renderTableRow(
              "Proved amount",
              `${xrpFromDrops(nftData.minimunAmount)} ${nftData?.currency}`,
            )}
            {renderTableRow("Ring size", nftData?.ringSize)}
          </TableBody>
        </Table>
      </TableContainer>

      <Accordion sx={accordionStyle}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ color: "#FAF9F6", borderRadius: "10px" }} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>
            Ring
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "240px",
              height: "240px",
              borderRadius: "50%",
              border: "2px solid purple",
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                color: "#FAF9F6",
                fontWeight: "bold",
                fontSize: "1.25rem",
                zIndex: 2,
              }}
            >
              {xrpFromDrops(nftData.minimunAmount)} {nftData?.currency}
            </Typography>
            {ring?.map((address, index) => {
              const angle = (360 / ring.length) * index;
              const rotation = {
                transform: `rotate(${angle}deg) translate(120px) rotate(-${angle}deg)`,
                transformOrigin: "0 50%",
              };

              const positionStyle = {
                ...rotation,
                position: "absolute",
                whiteSpace: "nowrap",
                display: "flex",
                alignItems: "center",
                "&:hover": {
                  transform: `scale(1.1) ${rotation.transform}`,
                  transition: "transform 0.2s ease-in-out",
                },
              };

              return (
                <Box key={index} sx={positionStyle}>
                  <a
                    href={`${XRPL_EXPLORER_BASE_URL}${address}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "#FAF9F6" }}
                  >
                    <Typography
                      sx={{ fontWeight: "bold", fontSize: "0.875rem" }}
                    >
                      {`${address.substring(0, 6)}...${address.substring(address.length - 4)}`}
                    </Typography>
                  </a>
                </Box>
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          marginTop: "20px",
        }}
      >
        {nftData.verified === undefined ? (
          <CircularProgress />
        ) : nftData.verified ? (
          <CheckCircleIcon sx={{ color: "green", fontSize: "40px" }} />
        ) : (
          <CancelIcon sx={{ color: "red", fontSize: "40px" }} />
        )}

        {!nftData.verified && (
          <Button
            variant="contained"
            color="error"
            onClick={() => handleBurnClick(nftData.NFTokenID)}
            sx={{ marginLeft: "16px" }}
          >
            Burn NFT
          </Button>
        )}
      </Box>
    </Grid>
  );
});

export default ProofDetails;

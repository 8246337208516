import { isInstalled, signMessage } from "gemwallet-custom-api";
import { getAddressFromGem } from "./getAddressFromGem";

/**
 * Sign a payload using gem wallet (this is not a ring signature)
 *
 * @throws if the expected address does not match the current gem address
 *
 * @param payload - The payload to sign
 * @param provingAddress - The address to sign with (optional)
 *
 * @returns - The signed payload
 */
export async function signWithGem(
  payload: string,
  expectedAddress?: string,
): Promise<string> {
  if (!payload) throw new Error("Non empty payload is required");

  // check if the expected address matches the current gem address
  if (expectedAddress && expectedAddress !== (await getAddressFromGem()))
    throw new Error("Signing address does not match the current gem address");

  if ((await isInstalled()).result?.isInstalled) {
    try {
      const signedMsg = (await signMessage(payload)).result?.signedMessage;
      if (signedMsg) {
        return signedMsg;
      }
      throw new Error("signed message is undefined");
    } catch (e: any) {
      console.error("Something went wrong: ", e);
      throw new Error("Something went wrong while signing message: ", e);
    }
  }

  throw new Error("Cannot sign: Gem wallet is not installed");
}

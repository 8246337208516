import React from "react";

const ProofInfoContext = React.createContext();
export const useProofInfo = () => React.useContext(ProofInfoContext);
export const ProofInfoProvider = ({ children }) => {
  const [userProofInfo, setUserProofInfo] = React.useState(0);

  return (
    <ProofInfoContext.Provider value={{ userProofInfo, setUserProofInfo }}>
      {children}
    </ProofInfoContext.Provider>
  );
};

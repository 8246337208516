import { isInstalled, signAlicesRing } from "gemwallet-custom-api";

export async function ringSignWithGemWallet(payload: any): Promise<string> {
  // First, ensure Gem Wallet is installed
  const isWalletInstalled = await isInstalled();
  if (!isWalletInstalled.result?.isInstalled) {
    throw new Error("Gem Wallet is not installed");
  }

  // Ensure payload is structured correctly according to SignAlicesRingRequest
  // This assumes `payload` already contains the necessary data in the correct format.
  // If `payload` is not structured correctly, you need to adjust it accordingly.
  const structuredPayload = { p0: payload }; // Adjust this line if payload structure differs

  try {
    const signingResult = await signAlicesRing(structuredPayload);
    if (signingResult.result?.ringSignature) {
      return signingResult.result.ringSignature;
    } else {
      throw new Error("Could not get final signature from Gem Wallet");
    }
  } catch (e: any) {
    console.error("Something went wrong: ", e);
    throw new Error(
      "Something went wrong while signing with Gem Wallet: " + e.message,
    );
  }
}

/**
 * Convert drops to readable XRP value
 *
 * @param drops - drops amount to convert
 *
 * @returns - XRP human readable value
 */
export function xrpFromDrops(drops: BigInt): string {
  const xrpDecimals = 6;

  const strDrops = drops.toString();

  if (strDrops.length <= xrpDecimals) {
    return `0.${strDrops.padStart(xrpDecimals - strDrops.length, "0")}`.replace(
      /\.?0+$/,
      "",
    );
  }

  const xrp = strDrops.slice(0, strDrops.length - xrpDecimals);
  const xrpDecimal = strDrops.slice(strDrops.length - xrpDecimals);

  // concat and remove all trailing zeros
  return `${xrp}.${xrpDecimal}`.replace(/\.?0+$/, "");
}

import React, { useState, useCallback } from "react";
import { Box, Stepper, Step, StepLabel, Tooltip } from "@mui/material";
import Forms from "./panel/Steps/Forms";
import DownloadWallet from "./panel/Steps/DownloadWallet";
import { useWalletInfo } from "../context/walletInfo";
import { useProofInfo } from "../context/proofInfo";
import SignRing from "./panel/Steps/SignRing";
import {
  MintRequestResult,
  RingGenerationResult,
  SigningResult,
} from "../interfaces";
import MintRequest from "./panel/Steps/MintRequest";
import MintRequestDetails from "./panel/Steps/MintRequestDetails"; // Import MintRequestDetails

const steps = [
  "Connect Wallet",
  "Set your proof parameters",
  "Sign Ring",
  "Mint Proof",
];

const stepInfo = [
  "Begin by connecting through GemWallet. Your address will manage fees and secure the SBT.",
  "Set parameters: Define amount, currency, signer's secret address, ring size, and an optional memo. You must have your secret address imported in GemWallet.",
  "Sign the ring with your secret address. This will generate your proof.",
  "Mint and accept the NFT offer to claim your SBT. This token will be used to verify your proof.",
];

const GenerateProofComponent: React.FC = () => {
  const { userWalletInfo } = useWalletInfo();
  const { userProofInfo, setUserProofInfo } = useProofInfo();
  const [activeStep, setActiveStep] = useState(0);
  const [ringGenerationResult, setRingGenerationResult] =
    useState<RingGenerationResult | null>(null);
  const [signRingResult, setSignRingResult] = useState<SigningResult | null>(
    null,
  );
  const [mintRequestResult, setMintRequestResult] =
    useState<MintRequestResult | null>(null);

  const handleValidated = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleRingGenerationResult = useCallback(
    (result: RingGenerationResult) => {
      setRingGenerationResult(result);
    },
    [],
  );

  const handleSignRingResult = useCallback((result: SigningResult) => {
    setSignRingResult(result);
  }, []);

  const handleMintRequestResult = useCallback((result: MintRequestResult) => {
    setMintRequestResult(result);
  }, []);

  const resetStepper = () => {
    setUserProofInfo(userProofInfo + 1);
    setActiveStep(0);
    setRingGenerationResult(null);
    setSignRingResult(null);
    setMintRequestResult(null);
  };

  const getStepContent = useCallback(
    (stepIndex: number) => {
      switch (stepIndex) {
        case 0:
          if (userWalletInfo.address) {
            handleValidated();
          } else {
            return <DownloadWallet />;
          }
          break;
        case 1:
          return (
            <Forms
              onValidated={handleValidated}
              onApiResult={handleRingGenerationResult}
            />
          );
        case 2:
          return (
            <SignRing
              onValidated={handleValidated}
              data={ringGenerationResult}
              onRingSignResult={handleSignRingResult}
            />
          );
        case 3:
          return (
            <MintRequest
              data={signRingResult}
              onMintRequestResult={handleMintRequestResult}
              onCloseClick={resetStepper} // Pass resetStepper as a prop
            />
          );
        default:
          return "Unknown step";
      }
    },
    [
      userWalletInfo.address,
      handleValidated,
      ringGenerationResult,
      signRingResult,
      handleRingGenerationResult,
      handleSignRingResult,
      handleMintRequestResult,
    ],
  );

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1000px",
        backgroundColor: "transparent",
        borderRadius: "10px",
        boxShadow: "none",
        color: "white",
        mx: "auto",
      }}
    >
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{ mb: 5, backgroundColor: "transparent", p: 3, borderRadius: 1 }}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={index < activeStep}>
            <Tooltip title={stepInfo[index]} arrow>
              <StepLabel
                StepIconProps={{
                  sx: {
                    color: "secondary.light",
                    "&.Mui-active": {
                      color: "secondary.main",
                      fontSize: "2rem",
                    },
                    "&.Mui-completed": { color: "secondary.dark" },
                  },
                }}
                sx={{
                  "& .MuiStepLabel-label": {
                    color: "common.white",
                    "&.Mui-active": {
                      color: "common.white",
                      fontWeight: "bold",
                    },
                    "&.Mui-completed": { textDecoration: "underline" },
                  },
                }}
              >
                {label}
              </StepLabel>
            </Tooltip>
          </Step>
        ))}
      </Stepper>
      <Box>{getStepContent(activeStep)}</Box>
    </Box>
  );
};

export default GenerateProofComponent;

import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  Box,
  Modal,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ProofDetails from "./ProofDetails";
import { xrpFromDrops } from "../../utils";

const POAP_DROP_EXPLORER_BASE_URL = "https://poap.gallery/event/";

const tableCellStyle = {
  color: "#FAF9F6",
  fontSize: "0.875rem",
  borderBottom: "none",
  padding: "16px",
};

const tableRowStyle = {
  backgroundColor: "#1A1A1A",
  "&:hover": { backgroundColor: "#292929" },
  transition: "background-color 0.3s",
};

const modalBoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "600px", // Set a maximum width for the modal
  maxHeight: "90vh",
  overflowY: "auto",
  backgroundColor: "#1A1A1A",
  border: "2px solid #000",
  boxShadow: 24,
  padding: "20px",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const ProofTable: React.FC<any> = ({ searchResults }) => {
  const [sortConfig, setSortConfig] = useState({
    key: "verified",
    direction: "ascending",
  });
  const [sortedResults, setSortedResults] = useState(searchResults);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNFT, setSelectedNFT] = useState<any | null>(null);

  useEffect(() => {
    // for each element of searchResults, add the img key with the value of the image url
    searchResults.forEach(async (element: any) => {
      if (element.currency === "POAP") {
        const eventLoaded = await fetch(
          `https://api.poap.tech/metadata/${element.eventId}/1`
        );

        if (!eventLoaded.ok) {
          element.img = "";
          return;
        }

        const jsonEventLoaded = await eventLoaded.json();
        element.img = jsonEventLoaded.image_url;
      }
    });
    console.log("img loaded");

    if (imgLoaded !== true) setImgLoaded(true);
  }, [searchResults, sortedResults]);

  useEffect(() => {
    (async () => {
      // wait for 3 seconds
      await new Promise((resolve) => setTimeout(resolve, 500));
      sortResults("verified");
    })();
  }, []);

  const handleOpenModal = (nft: any) => {
    setSelectedNFT(nft);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedNFT(null);
  };

  const sortResults = async (key: string) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const newSortedResults = [...searchResults].sort((a, b) => {
      let aValue = a[key];
      let bValue = b[key];
      if (!isNaN(aValue) && !isNaN(bValue)) {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);
      }
      if (aValue < bValue) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setSortedResults(newSortedResults as any);
  };

  const renderSortIcon = (key: string) => {
    return sortConfig.key === key ? (
      sortConfig.direction === "ascending" ? (
        <ArrowUpwardIcon />
      ) : (
        <ArrowDownwardIcon />
      )
    ) : null;
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: { xs: "50vh", sm: "60vh", md: "70vh", lg: "80vh" },
          overflowY: "auto",
          overflowX: "auto",
          boxShadow: "none",
          width: "100%",
          backgroundColor: "#1A1A1A",
          marginBottom: "10px",
          borderRadius: "10px",
        }}
      >
        <Table sx={{ tableLayout: "auto" }}>
          <TableHead>
            <TableRow sx={tableRowStyle}>
              <TableCell
                onClick={() => sortResults("verified")}
                sx={{ ...tableCellStyle, cursor: "pointer" }}
                key="verified"
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  Validity
                  {renderSortIcon("verified")}
                </Box>
              </TableCell>
              <TableCell
                onClick={() => sortResults("minimunAmount")}
                sx={{ ...tableCellStyle, cursor: "pointer" }}
                key="minimunAmount"
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  Amount
                  {renderSortIcon("minimunAmount")}
                </Box>
              </TableCell>
              <TableCell sx={tableCellStyle}>Currency</TableCell>
              <TableCell
                onClick={() => sortResults("customMessage")}
                sx={{ ...tableCellStyle, cursor: "pointer" }}
                key="customMessage"
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  Message
                  {renderSortIcon("customMessage")}
                </Box>
              </TableCell>
              <TableCell
                onClick={() => sortResults("createdAtLedger")}
                sx={{ ...tableCellStyle, cursor: "pointer" }}
                key="createdAtLedger"
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  Created At Ledger
                  {renderSortIcon("createdAtLedger")}
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedResults.map((nft: any, index: any) => (
              <TableRow
                key={index}
                sx={{
                  "&:nth-of-type(odd)": { backgroundColor: "#333" },
                  cursor: "pointer",
                }}
                onClick={() => handleOpenModal(nft)}
              >
                <TableCell sx={tableCellStyle}>
                  {nft.verified ? (
                    <CheckCircleIcon style={{ color: "green" }} />
                  ) : (
                    <CancelIcon style={{ color: "red" }} />
                  )}
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  {nft.currency === "XRP" && xrpFromDrops(BigInt(nft.minimunAmount))}
                  {imgLoaded && nft.currency === "POAP" && (
                    <img
                      src={nft.img}
                      alt="POAP"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent row click event
                        window.open(
                          `${POAP_DROP_EXPLORER_BASE_URL}${nft.eventId}`,
                          "_blank"
                        );
                      }}
                    />
                  )}
                </TableCell>
                <TableCell sx={tableCellStyle}>{nft.currency}</TableCell>
                <TableCell sx={tableCellStyle}>{nft.customMessage}</TableCell>
                <TableCell sx={tableCellStyle}>{nft.createdAtLedger}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box sx={modalBoxStyle}>
          {selectedNFT ? <ProofDetails nftData={selectedNFT} /> : <div />}
        </Box>
      </Modal>
    </>
  );
};

export default ProofTable;

import { Client } from "xrpl";
import { getWSSNetworkProvider } from "./getNetworkProvider";

export async function getAccountBalance(
  account: string,
  network: string,
): Promise<bigint> {
  try {
    const xrplWssUrl = getWSSNetworkProvider(network);
    const client = new Client(xrplWssUrl);
    await client.connect();
    const response = await client.request({
      command: "account_info",
      account,
      ledger_index: "current",
    });
    return BigInt(response.result.account_data.Balance);
  } catch (error) {
    console.error("Error getting account balance:", error);
    return -1n;
  }
}
